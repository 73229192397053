import { differenceInDays } from 'date-fns'
import { TGanttLinkType } from '../../../../api/relations/relations.def'
import { TGanttLinkDates } from './GanttLinkByFactRestrictionDialog.def'

export const calculateGanttLinkLag = (linkType: TGanttLinkType, dates: TGanttLinkDates) => {
    const mapByLinkType: Record<TGanttLinkType, () => number> = {
        '0': () => differenceInDays(dates?.targetStartDate, dates?.sourceEndDate),
        '1': () => differenceInDays(dates?.targetStartDate, dates?.sourceStartDate),
        '2': () => differenceInDays(dates?.targetEndDate, dates?.sourceEndDate),
        '3': () => differenceInDays(dates?.targetEndDate, dates?.sourceStartDate),
    }

    return mapByLinkType[linkType]() ?? 0
}
