import { Button, IconButton, Input, Menu, MenuItem, Popover, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { AiOutlineSearch } from 'react-icons/ai'
import { IoIosArrowDown } from 'react-icons/io'
import s from './Header.module.scss'
import { headerButton, headerInput } from './Header.service'
import { Link, useMatches, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import React, { MouseEvent, useEffect, useState } from 'react'
import { useGetProjectsQuery } from '../../api/projects/projects.api'
import { projectsSelector, setHeaderSearch } from '../../store/slices/projectsSlice'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { svgMainLogo } from '../../shared/SVG/Svg'
import PeopleIcon from '@mui/icons-material/People'
import { currentCompanyName, deployLinks } from '../../shared/config/config'
import { setPage } from '../../store/slices/paginationSlice'
import { profileSelector } from '../../store/slices/profileSlice'
import { HtmlTooltip } from '../../shared/components/HtmlTooltip'
import BreadcrumbsEl from './components/BreadcrumbsEL'
import DrawersEl from './DrawersEl'

function Header() {
    const [openPopover, setOpenPopover] = useState<HTMLButtonElement | null>(null)
    const [openPopoverLinks, setOpenPopoverLinks] = useState<HTMLButtonElement | null>(null)

    const { headerSearch, pageName } = useTypedSelector(projectsSelector)
    const { profile } = useTypedSelector(profileSelector)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { projectId } = useParams()
    const [urlSearchParams, setUrlSearchParams] = useSearchParams()
    const { t } = useTranslation('user')
    const match = useMatches()
    const searchIDs = ['0-1-0', '0-2-0']
    const isSearchActive = match.find(match => {
        return searchIDs.includes(match?.id)
    })

    const projects = useGetProjectsQuery({})

    const projectTitle = projects.data?.data.find((project) => project.id === Number(projectId))?.title

    useEffect(() => {
        dispatch(setHeaderSearch(''))
    }, [dispatch, pageName])

    const displayAvatar = (big?: 'big') => {
        const firstLetterName = profile?.firstName?.substring(0, 1).toUpperCase()
        const lastLetterName = profile?.lastName?.substring(0, 1).toUpperCase()

        if (profile?.avatar?.length) {
            return <img src={profile?.avatar} alt="avatar" />
        } else {
            return (
                <div className={big ? s.rounded_letters : s.rounded_letters_small}>
                    <div className={s.rl_inside}>
                        <p>{firstLetterName}</p>
                        <p>{lastLetterName}</p>
                    </div>
                </div>
            )
        }
    }

    const companyParam = currentCompanyName ? `?company=${currentCompanyName}` : ''

    if (!profile.firstName) {
        return <></>
    }

    return (
        <div className={s.component}>
            <div className={s.container}>
                <div className={s.left}>
                    <Link to="/">
                        <div className={s.main_logo}>{svgMainLogo}</div>
                    </Link>

                    <BreadcrumbsEl setOpenPopoverLinks={setOpenPopoverLinks} projectTitle={projectTitle || ''} />

                    <Menu
                        open={!!openPopoverLinks}
                        anchorEl={openPopoverLinks}
                        onClose={() => setOpenPopoverLinks(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        {projects.data?.data.map((v) => (
                            <Link
                                onClick={() => {
                                    dispatch(setPage(1))
                                    setOpenPopoverLinks(null)
                                }}
                                key={v.id}
                                to={
                                    urlSearchParams.toString()
                                        ? `/ksg/${v.id}?${urlSearchParams.toString()}`
                                        : `/ksg/${v.id}`
                                }
                            >
                                <MenuItem>
                                    <Typography width={'18rem'} color="#2b3648" variant="body1" noWrap>
                                        {v.title}
                                    </Typography>
                                </MenuItem>
                            </Link>
                        ))}
                    </Menu>
                    <span></span>
                </div>
                <div className={s.right}>
                    {isSearchActive && (
                        <Input
                            disableUnderline={true}
                            sx={headerInput}
                            value={headerSearch}
                            onChange={(e) => dispatch(setHeaderSearch(e.target.value))}
                            placeholder="Поиск"
                            startAdornment={<AiOutlineSearch color="rgb(92, 110, 140)" />}
                            endAdornment={
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => dispatch(setHeaderSearch(''))}
                                >
                                    <CloseIcon />
                                </IconButton>
                            }
                        />
                    )}

                    <Button
                        sx={{
                            textTransform: 'capitalize',
                        }}
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setOpenPopover(event.currentTarget)
                        }}
                    >
                        <div className={s.button_profile}>
                            {displayAvatar()}
                            <p>{profile?.firstName + ' ' + profile?.lastName}</p>
                            <IoIosArrowDown fontSize="1rem" />
                        </div>
                    </Button>

                    <Popover
                        open={!!openPopover}
                        anchorEl={openPopover}
                        onClose={() => setOpenPopover(null)}
                        sx={{
                            '.MuiPopover-paper': {
                                borderRadius: '8px',
                            },
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <div className={s.popover}>
                            <h2>{profile?.company?.companyName}</h2>
                            <div className={s.popover_content}>
                                {displayAvatar('big')}

                                <div className={s.popover_description}>
                                    <h4>{profile?.lastName || 'Нет доступа'}</h4>
                                    <h4>{(profile?.firstName || '') + ' ' + (profile?.middleName || '')}</h4>

                                    <div>
                                        <h5>{profile?.company?.userCompanyName}</h5>
                                        <p>{t(`roles.${profile?.role}`)}</p>
                                    </div>
                                </div>
                            </div>
                            <Button
                                sx={{
                                    marginBottom: '1.5rem',
                                    fontSize: 14,
                                    borderRadius: '8px',
                                }}
                                fullWidth
                                variant="contained"
                                href={`${deployLinks.core}${companyParam}`}
                            >
                                К Выбору модуля
                            </Button>

                            <Button
                                sx={{
                                    fontSize: 14,
                                    borderRadius: '8px',
                                }}
                                href={`${deployLinks.core}${companyParam}`}
                                onClick={() => {
                                    localStorage.removeItem('auth')
                                }}
                                fullWidth
                                variant="contained"
                            >
                                Выйти из модуля
                            </Button>
                        </div>
                    </Popover>

                    <HtmlTooltip title="Проекты">
                        <Button onClick={() => navigate('/')} sx={headerButton}>
                            <DescriptionOutlinedIcon />
                        </Button>
                    </HtmlTooltip>

                    {profile?.role !== 'executor' && (
                        <HtmlTooltip title="Пользователи">
                            <Button onClick={() => navigate('/users')} sx={headerButton}>
                                <PeopleIcon />
                            </Button>
                        </HtmlTooltip>
                    )}
                </div>
            </div>

            <DrawersEl />
        </div>
    )
}

export default Header
