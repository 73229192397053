import { TGanttLink } from '../../../../api/relations/relations.def'
import { req } from '../../../WorkManagment/api/api'
import { ITask, TLocalGanttLinkId } from '../../DHTGant.def'
import { RowData } from './GanttLinkDialog.def'
import { useEffect, useMemo, useState } from 'react'
import { formatLinkType } from './GanttLinkDialog.utils'

export const useGetRowData = (tasks: ITask[], projectId: string, selectedTaskId: TLocalGanttLinkId) => {
    const [rowData, setRowData] = useState<RowData[]>([])
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false)
    useEffect(() => {
        if (!selectedTaskId) return
        req.post(`/projects/${projectId}/dependencies/works/ksg/links`, {
            included: [selectedTaskId],
        })
            .then(({ data }) => {
                const links = data.links as TGanttLink[]
                setRowData(() =>
                    links.map((link, index) => {
                        const task = tasks.find((el) =>
                            link.target === selectedTaskId ? el.id === link.source : el.id === link.target
                        )
                        return {
                            id: task?.id!,
                            number: task?.numOrder!,
                            name: task?.workName!,
                            linkType: formatLinkType(link.type, true),
                            lag: link.lag,
                        }
                    })
                )
            })
            .catch((err) => {
                console.log('error', err)
            })
    }, [projectId, selectedTaskId, refreshToggle])

    return {
        rowData,
        setRowData,
        refreshToggle,
        setRefreshToggle,
    }
}
