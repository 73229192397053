import { ICellRendererParams, IHeaderParams, INoRowsOverlayParams } from 'ag-grid-community'
import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ICustomCellDialogEditorParams, INumberCellProps } from './GanttLinkDialog.def'
import {
    BaseCellTextDate,
    BaseCellText,
    CellWithPlaceHolder,
    CustomNoRows,
    NumberCellCheckbox,
    NumberHeaderCellContainer,
    NumberHeaderCellText,
} from './GanttLinkDialog.styles'

import {
    Autocomplete,
    FilterOptionsState,
    ListItem,
    Switch,
    TextField,
    Typography,
    createFilterOptions,
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { format, parse } from 'date-fns'
import { ru } from 'date-fns/locale'

import { Controller, useForm } from 'react-hook-form'
import { theme } from '../../../../styles/theme'

import { OverflowableTypographyWithTooltip } from '../../../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import {
    IAutocompleteOption,
    ICustomCellEditorParams,
} from '../../../WorkManagment/components/CellEditors/CellEditor/CellEditor.def'
import { getDefaultValidationRules } from '../../../WorkManagment/components/CellEditors/CellEditor/CellEditor.service'
import { COLORS } from '../../../WorkManagment/components/PercentStatusValue/PercentStatusValue.style'
import {
    ControllerInputWrapper,
    EditorTextField,
    StyledNumberFormat,
    StyledTooltip,
} from '../../../WorkManagment/components/components.styles'

const isNumberCell = (props: ICellRendererParams | IHeaderParams): props is ICellRendererParams => 'value' in props

export const NumberCell: React.FC<INumberCellProps> = (props) => {
    const isHeader = !isNumberCell(props)
    const value = isHeader ? '№' : props.value
    const [isChecked, setIsChecked] = useState(!isHeader && props.node.isSelected())

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!isHeader) {
            if (!props.data.number) return
            props.node.setSelected(e.target.checked)
            setIsChecked(e.target.checked)
            if (!e.target.checked) {
                props.setTargets((prevTargets) => {
                    const newTargets = new Set(prevTargets)
                    newTargets.delete(props.data.id || props.data.number)
                    return newTargets
                })
            } else {
                props.setTargets((prevTargets) => new Set(prevTargets).add(props.data.id || props.data.number))
            }
        } else {
            setIsChecked(e.target.checked)
            props.onHeaderCheckboxChange(e)
        }
    }

    useEffect(() => {
        if (isHeader) {
            props.targets.size && props.api.getDisplayedRowCount() === props.targets.size
                ? setIsChecked(true)
                : setIsChecked(false)
            return
        }
        props.targets.has(props.data.id || props.data.number) ? setIsChecked(true) : setIsChecked(false)
    }, [props.targets])

    return (
        <NumberHeaderCellContainer>
            <NumberCellCheckbox
                checked={isChecked}
                onChange={handleChange}
                // disabled={true}
            />

            <NumberHeaderCellText isHeader={isHeader}>{value}</NumberHeaderCellText>
        </NumberHeaderCellContainer>
    )
}

export const BaseCell = (props: ICellRendererParams) => {
    return <BaseCellText>{props.value}</BaseCellText>
}

export const BaseCellDate = (props: ICellRendererParams) => {
    return <BaseCellTextDate>{props.value}</BaseCellTextDate>
}

interface PlaceholderCellRendererProps extends ICellRendererParams {
    placeholder: string
}

export const PlaceholderCellRenderer: React.FC<PlaceholderCellRendererProps> = (props) => {
    const value = props.colDef?.field !== 'lag' ? props.value || props.placeholder : props.value + 'д'

    return (
        <>
            {props.value ? (
                <OverflowableTypographyWithTooltip maxRows={1}>
                    <BaseCellText>{typeof value == 'object' ? value.value : value}</BaseCellText>
                </OverflowableTypographyWithTooltip>
            ) : (
                <OverflowableTypographyWithTooltip maxRows={1}>
                    <CellWithPlaceHolder>{value}</CellWithPlaceHolder>{' '}
                </OverflowableTypographyWithTooltip>
            )}
        </>
    )
}

export const CustomCellEditor = forwardRef(
    (
        {
            dataType = 'number',
            options = [],
            validation,
            createFilterOptionsConfig,
            node,
            colDef,
            getOptions,
            searchOptions,
            ...rest
        }: ICustomCellDialogEditorParams,
        ref
    ) => {
        const [value, setValue] = useState<number | string | boolean | IAutocompleteOption>(rest.value)
        const [rowsCount, setRowsCount] = useState(0)
        const {
            control,
            formState,
            setValue: setFormValue,
            trigger,
        } = useForm({
            mode: 'all',
            reValidateMode: 'onChange',
        })

        const [isLoadingAutocompleteOptions, setIsLoadingAutocompleteOptions] = useState(true)
        const [autocompleteOptions, setAutocompleteOptions] = useState<IAutocompleteOption[]>(options ?? [])
        const [inputValue, setInputValue] = useState('');

        useEffect(() => {
            rest?.eGridCell?.getElementsByTagName('input')[0]?.select()
            rest?.eGridCell?.getElementsByTagName('input')[0]?.focus()
            rest?.eGridCell?.getElementsByTagName('textarea')[0]?.select()
            rest?.eGridCell?.getElementsByTagName('textarea')[0]?.focus()
        }, [])

        useEffect(() => {
            if (!getOptions) return

            getOptions()
                .then((options) => {
                    setAutocompleteOptions(options)
                })
                .finally(() => {
                    setIsLoadingAutocompleteOptions(false)
                })
        }, [])

        useEffect(() => {
            if (!searchOptions || !inputValue) return

            searchOptions(inputValue as string)
                .then((options) => {
                    setAutocompleteOptions(options)
                })
                .finally(() => {
                    setIsLoadingAutocompleteOptions(false)
                })
        }, [inputValue, searchOptions])

        useEffect(() => {
            dataType == 'date' && rest?.eGridCell?.getElementsByTagName('input')[0]?.focus()
        }, [dataType, formState.isValid, rest?.eGridCell])

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                focusIn() {
                    return true
                },
                // the final value to send to the grid, on completion of editing
                getValue() {
                    if (dataType == 'autocomplete') {
                        if (value == null) {
                            return null
                        }
                        if (typeof value == 'object') {
                            return {
                                ...(value as IAutocompleteOption),
                                label: (value as IAutocompleteOption)?.label || '',
                            }
                        }
                        return (value as string) || ''
                    }
                    return value
                },
                // Cancel all changes, if input is invalid
                isCancelAfterEnd() {
                    return !formState.isValid
                },
            }
        })

        let defaultValidationSchema: ICustomCellEditorParams['validation'] = getDefaultValidationRules(dataType)

        const filter = createFilterOptions<IAutocompleteOption>(createFilterOptionsConfig)

        // console.log('Это дефолтное значение', value)

        return (
            <Controller
                name={'input'}
                control={control}
                defaultValue={dataType === 'date' ? value && parse(value as string, 'MM.yyyy', new Date()) : value}
                rules={{
                    ...defaultValidationSchema,
                    ...validation,
                    validate: {
                        ...defaultValidationSchema?.validate,
                        ...validation?.validate,
                    },
                }}
                render={({ field, formState, fieldState }) => {
                    const isInvalid = !!formState.errors['input']
                    const errorMessage =
                        (formState.errors['input']?.message && formState.errors['input']?.message.toString()) || ''

                    return (
                        <StyledTooltip
                            title={errorMessage}
                            color={'error'}
                            placement="right"
                            open={!!errorMessage}
                            PopperProps={{
                                disablePortal: false,
                            }}
                        >
                            <ControllerInputWrapper error={isInvalid} onClick={(e) => e.preventDefault()}>
                                {dataType == 'number' && (
                                    <StyledNumberFormat
                                        defaultValue={rest.value}
                                        // value={field.value}
                                        allowedDecimalSeparators={['.', ',']}
                                        decimalSeparator=","
                                        onFocus={(e: { target: { select: () => void } }) => e.target.select()}
                                        onValueChange={(values: { floatValue: any }) => {
                                            setValue((prevValue) => values.floatValue)
                                            field.onChange(values.floatValue)
                                        }}
                                        valueIsNumericString
                                        {...(isInvalid && {
                                            style: {
                                                color: COLORS.error.color,
                                            },
                                        })}
                                        thousandSeparator={' '}
                                        {...rest.NumericFormatProps}
                                    />
                                )}
                                {dataType == 'text' && (
                                    <EditorTextField
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e)
                                            setValue((prevValue) => e.target.value)
                                        }}
                                        fullWidth
                                        {...rest.TextFieldProps}
                                    />
                                )}
                                {dataType == 'textarea' && (
                                    <EditorTextField
                                        defaultValue={rest.value}
                                        ref={field.ref}
                                        name={field.name}
                                        onChange={(e) => {
                                            field.onChange(e)
                                            trigger(field.name)
                                            setValue((prevValue) => e.target.value)
                                        }}
                                        fullWidth
                                        {...rest.TextFieldProps}
                                    />
                                )}
                                {dataType == 'date' && (
                                    <LocalizationProvider adapterLocale={ru} dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            {...field}
                                            inputFormat="MM.yyyy"
                                            mask={'__.____'}
                                            onChange={(date, string) => {
                                                field.onChange(date)
                                                setValue(string || '')
                                                setFormValue(
                                                    'input',
                                                    string ? parse(string || '', 'MM.yyyy', new Date()) : date,
                                                    {
                                                        shouldValidate: true,
                                                    }
                                                )
                                            }}
                                            onAccept={(date) => {
                                                setValue(() => format(date, 'MM.yyyy'))
                                                field.onChange(date)
                                                setFormValue('input', date, { shouldValidate: true })
                                                setTimeout(() => {
                                                    !formState.errors?.input && rest.api.stopEditing()
                                                })
                                            }}
                                            closeOnSelect
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        onClick={(e) => {
                                                            e.currentTarget.classList.contains('MuiFormControl-root') &&
                                                                e.preventDefault()
                                                        }}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: 'мм.гггг',
                                                            style: {
                                                                textAlign: 'left',
                                                            },
                                                        }}
                                                        sx={{
                                                            '.MuiOutlinedInput-root': {
                                                                fontSize: '14px',
                                                            },
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                display: 'none',
                                                            },
                                                            input: {
                                                                paddingLeft: 0,
                                                                paddingRight: 0,
                                                                textAlign: 'left',
                                                            },
                                                            ...rest.TextFieldProps?.sx,
                                                        }}
                                                        {...rest.TextFieldProps}
                                                    />
                                                )
                                            }}
                                            views={['year', 'month']}
                                            openTo="month"
                                        />
                                    </LocalizationProvider>
                                )}
                                {dataType == 'autocomplete' && (
                                    //@ts-ignore
                                    <Autocomplete
                                        renderInput={(autocompleteParams) => (
                                            <EditorTextField
                                                {...field}
                                                {...autocompleteParams}
                                                value={value}
                                                placeholder="Введите или выберите значение"
                                            />
                                        )}
                                        onChange={(e, newValue) => {
                                            field.onChange(e)
                                            setValue(() => newValue as IAutocompleteOption)
                                        }}
                                        onInputChange={(event, value) => {
                                            setInputValue(value)}}
                                        disableClearable={true}
                                        defaultValue={!value ? null : (value as string)}
                                        fullWidth
                                        loading={isLoadingAutocompleteOptions}
                                        loadingText="Загрузка..."
                                        options={autocompleteOptions}
                                        noOptionsText="Нет значений"
                                        renderOption={(autocompleteProps, option) => (
                                            <ListItem
                                                //variant="body2"
                                                disabled={
                                                    typeof value == 'object'
                                                        ? option.label === value.label
                                                        : option.id === node.data.id || option.label === value
                                                }
                                                color={theme.palette.text.primary}
                                                {...autocompleteProps}
                                            >
                                                {option.label}
                                            </ListItem>
                                        )}
                                        {...rest.AutocompleteProps}
                                        {...(rest.withNewOptionCreation && {
                                            freeSolo: true,
                                            selectOnFocus: true,
                                            clearOnBlur: true,
                                            forcePopupIcon: true,
                                            filterOptions: (
                                                options: any[],
                                                params: FilterOptionsState<IAutocompleteOption>
                                            ) => {
                                                const filtered = filter(autocompleteOptions, params)

                                                const { inputValue } = params
                                                // Suggest the creation of a new value
                                                const isExisting = autocompleteOptions.some(
                                                    (option) => inputValue === option.label
                                                )
                                                if (inputValue !== '' && !isExisting && !errorMessage) {
                                                    filtered.push({
                                                        value: inputValue,
                                                        label: inputValue,
                                                    })
                                                }
                                                return filtered
                                            },
                                            getOptionLabel: (option: IAutocompleteOption) => {
                                                return option.label || option
                                            },
                                        })}
                                    />
                                )}
                                {dataType === 'switch' && (
                                    <Switch
                                        {...field}
                                        // disabled={!rest.colDef.editable}
                                        onChange={(e) => {
                                            field.onChange(e)
                                            setValue((prevValue) => e.target.checked)
                                        }}
                                    />
                                )}
                            </ControllerInputWrapper>
                        </StyledTooltip>
                    )
                }}
            />
        )
    }
)

export const CustomNoRowsOverlay: React.FC<INoRowsOverlayParams> = () => {
    return <CustomNoRows>Нет данных для отображения</CustomNoRows>
}
