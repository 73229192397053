import { addMonths, format, isAfter, isBefore, isValid, lastDayOfMonth, parse, subMonths } from 'date-fns';
import { gantt } from 'dhtmlx-gantt';
import { v4 as uuidv4 } from 'uuid';

import { Work, monthlyChart } from '@/api/ksg/ksg.def';

import { TFeatureGuard } from '@/hooks/usePermissionsByRole';

import {
    ILibGanttLink,
    ITask,
    TCreateTaskProps,
    TDeleteLinksBetweenTasksProps,
    TIsTaskOutsideChartRangeProps,
    TLocalGanttLinkId,
    WorkWithStringId,
} from './DHTGant.def';
import { useGetProjectDates } from './DHTGantOnlyTable.model';
import './customStylesOverrides.scss';
import { GanttStatic } from './dhtmlxgantt';
import './dhtmlxgantt.css';
import { Task } from './dhtmlxgantt.js';

export const getScrollDirection = (top: number, prev: number) => {
    if (top === prev) return 'none';
    if (top < prev) return 'up';
    return 'down';
};

export const parseStringToDate = (date: string | null | undefined, formatValue = 'dd.MM.yyyy') => {
    if (!date) return null;
    const parsedDate = parse(date as string, formatValue, new Date());
    const isValidDate = isValid(parsedDate);
    return isValidDate ? parsedDate : null;
};

export const formatDateToString = (date: Date | null | undefined, formatValue = 'dd.MM.yyyy') => {
    if (!date) return null;
    return format(date, 'dd.MM.yyyy');
};

const calculateTaskProgress = (plan: number | null, fact: number | null) => (plan ? (fact ?? 0) / plan : 0);
const getTaskText = (level: number | null, name: string) => `<b>Ур. ${level}.</b> ${name}`;
const calculateSplitTaskProgress = (
    generalProgress: number,
    generalDuration: number,
    durations: number[]
): number[] => {
    let remainingProgress = generalProgress * generalDuration;
    return durations.map((duration) => {
        if (remainingProgress <= 0) {
            return 0;
        } else if (remainingProgress >= duration) {
            remainingProgress -= duration;
            return 1;
        } else {
            const progress = remainingProgress / duration;
            remainingProgress = 0;
            return progress;
        }
    });
};

export const createTask = ({ work, isParentExists, isChildrenExists }: TCreateTaskProps): ITask => {
    const task: ITask = {
        ...work,
        bar_height: work.hasChildren ? 6 : 10,
        parent: isParentExists ? work?.id_parent : null,
        workName: work?.workName,
        text: work.hasChildren ? '' : getTaskText(work?.level, work?.workName),
        expanded: true,
        progress: calculateTaskProgress(work?.volumeDonePlan, work?.volumeDoneFact),
        type: work.hasChildren && isChildrenExists ? 'project' : 'task',
        //render: work.hasChildren ? '' : work.render,
        isProjectCollapsed: work.hasChildren && !isChildrenExists,
    };

    const startDate = parseStringToDate(work.operationalStartDate);
    const endDate = parseStringToDate(work.operationalEndDate);

    if (startDate) {
        task.start_date = startDate;
    }

    if (endDate) {
        task.end_date = endDate;
    }

    return task;
};

export const createIsParentExists = (works: WorkWithStringId[]) => {
    const workIdsSet = new Set(works.map((item) => item.id));
    return (parentId: number | null) => (parentId ? workIdsSet.has(parentId) : false);
};

export const createIsChildExists = (works: WorkWithStringId[]) => {
    const parentIdsSet = new Set(works.map((v) => v.id_parent));
    return (id: number) => (id ? parentIdsSet.has(id) : false);
};

export const addChildSplitTask = (task: ITask) => {
    const segmentsDuration = [] as number[];
    const generalDuration = task.segments.reduce((sumDuration, segment) => {
        const startDate = parseStringToDate(segment.startDate);
        const endDate = parseStringToDate(segment.endDate);
        const duration = gantt.calculateDuration(startDate as Date, endDate as Date);
        segmentsDuration.push(duration);
        return sumDuration + gantt.calculateDuration(startDate as Date, endDate as Date);
    }, 0);
    const segmentsProgress = calculateSplitTaskProgress(task.progress, generalDuration, segmentsDuration);
    return task.segments.map((segment, i) => {
        const startDate = parseStringToDate(segment.startDate);
        const endDate = parseStringToDate(segment.endDate);
        const childTask = {
            ...task,
            id: uuidv4(),
            parent: task.id,
            open: true,
            segments: [],
            render: '',
            split: true,
            progress: segmentsProgress[i],
        };
        if (startDate) {
            childTask.start_date = startDate;
        }

        if (endDate) {
            childTask.end_date = endDate;
        }
        return childTask;
    });
};

export const getGanttLinksBySourceAndTarget = (links: ILibGanttLink[], source: number, target: number) => {
    const isMatchingLink = (link: ILibGanttLink, source: number, target: number) => {
        const linkSource = Number(link.source);
        const linkTarget = Number(link.target);

        if (linkSource === source && linkTarget === target) return true;
        if (linkSource === target && linkTarget === source) return true;
        return false;
    };

    return links.filter((link) => isMatchingLink(link, source, target));
};

export const deleteLinksBetweenTasks =
    (gantt: GanttStatic) =>
    ({ source, target }: TDeleteLinksBetweenTasksProps) => {
        const links = gantt.getLinks() as ILibGanttLink[];
        const filteredLinks = getGanttLinksBySourceAndTarget(links, source, target);
        filteredLinks.forEach((link) => deleteLinkPermanently(gantt, link.id));
    };

export const deleteLinkPermanently = (gantt: GanttStatic, linkId: TLocalGanttLinkId) => {
    const link = gantt.getLink(linkId);
    link.canDelete = true;
    gantt.refreshLink(linkId);
    gantt.deleteLink(linkId);
};

export const batchUpdateTasksDates = (gantt: GanttStatic) => (works: Work[]) => {
    gantt.batchUpdate(() => {
        works.forEach((work: Work) => {
            const task = gantt.getTask(work.id);
            const startDate = parseStringToDate(work.operationalStartDate) ?? undefined;
            const endDate = parseStringToDate(work.operationalEndDate) ?? undefined;

            task.start_date = startDate;
            task.end_date = endDate;
            gantt.updateTask(task.id);
        });
    });
};

const PROJECT_DATE_LINE_WIDTH = 22;

export const addLayers = (gantt: GanttStatic, projectDates: ReturnType<typeof useGetProjectDates>) => {
    // Базовый план
    gantt.addTaskLayer({
        renderer: {
            render: (task: Task) => {
                if (task.duration) {
                    return drawBasePlan(gantt, task);
                }
                return null;
            },
        },
    });

    // Линия старт проекта
    gantt.addTaskLayer({
        renderer: {
            render: (task: Task) => {
                return drawProjectDateLine(gantt, {
                    task,
                    date: addMonths(projectDates!.start, 1),
                    text: 'Старт проекта',
                    className: 'project_date_line--start',
                    offset: PROJECT_DATE_LINE_WIDTH,
                });
            },
        },
    });

    // Линия конец проекта
    gantt.addTaskLayer({
        renderer: {
            render: (task: Task) => {
                return drawProjectDateLine(gantt, {
                    task,
                    date: subMonths(projectDates!.end, 1),
                    text: 'Конец проекта',
                    className: 'project_date_line--end',
                });
            },
        },
    });
};

const drawProjectDateLine = (
    gantt: GanttStatic,
    {
        task,
        date,
        text,
        className,
        offset = 0,
    }: {
        task: Task;
        date: Date;
        text: string;
        className: string;
        offset?: number;
    }
) => {
    const element = document.createElement('div');
    element.className = `gantt_task_line project_date_line ${className}`;
    element.setAttribute('data-task-id', task.id.toString());
    const sizes = gantt.getTaskPosition(task);

    if (task.level === 0) {
        element.innerHTML = `<span class="project_date_line_text">${text}</span>`;
    }

    element.style.left = gantt.posFromDate(date) - offset + 'px';
    element.style.top = sizes.top + 'px';
    element.style.height = sizes.rowHeight + 2 + 'px';
    element.style.width = PROJECT_DATE_LINE_WIDTH + 'px';
    return element;
};

const drawBasePlan = (gantt: GanttStatic, task: Task) => {
    if (!task.startDate || !task.endDate) return;

    const start_base_plan_date = parseStringToDate(task.startDate) as Date;
    const end_base_plan_date = parseStringToDate(task.endDate) as Date;
    const basePlanStart = gantt.date.add(new Date(start_base_plan_date), 0, 'day');
    const basePlanEnd = gantt.date.add(new Date(end_base_plan_date), 0, 'day');
    const basePlan = document.createElement('div');
    basePlan.className = `gantt_task_line base_bar ${task.hasChildren ? 'base_bar--parent' : ''}`;
    basePlan.setAttribute('data-task-id', task.id.toString());
    const sizes = gantt.getTaskPosition(task, basePlanStart, basePlanEnd);
    basePlan.style.left = sizes.left + 'px';
    basePlan.style.top = sizes.top + sizes.rowHeight / 2 - 16 + 'px';
    basePlan.style.height = sizes.height + 'px';

    const width = gantt.posFromDate(basePlanEnd) - gantt.posFromDate(basePlanStart);
    basePlan.style.width = width + 'px';

    // const getTextByWidth = (width: number) => {
    //     if (width >= 100) return 'Базовый план';
    //     if (width >= 20) return 'БП';
    //     return '';
    // };

    // basePlan.innerHTML = getTextByWidth(width)
    return basePlan;
};

const getMonthlyChartDateRange = (charts: monthlyChart[]): [Date, Date] => {
    const parseChartToDate = (chart: monthlyChart) =>
        parseStringToDate(`${chart.month}:${chart.year}`, 'MM:yyyy') as Date;

    const start = parseChartToDate(charts.at(0)!);
    const end = lastDayOfMonth(parseChartToDate(charts.at(-1)!));
    return [start, end];
};

const isTaskOutsideChartRange = ({
    taskStartDate,
    taskEndDate,
    chartStartDate,
    chartEndDate,
}: TIsTaskOutsideChartRangeProps) => {
    const isTaskStartBeforeChartStart = isBefore(taskStartDate, addMonths(chartStartDate, 1));
    const isTaskEndAfterChartEnd = isAfter(taskEndDate, subMonths(chartEndDate, 1));
    const isTaskWithinChartRange = isTaskStartBeforeChartStart && isTaskEndAfterChartEnd;

    return !isTaskWithinChartRange;
};

export const isTaskDateRangeOutOfFact = (task: ITask) => {
    const filteredChartsWithFact = task.monthlyCharts.filter((v) => v.fact !== null);
    if (!filteredChartsWithFact.length) return false;

    const taskStartDate = task.start_date as Date;
    const taskEndDate = task.end_date as Date;
    const [chartStartDate, chartEndDate] = getMonthlyChartDateRange(filteredChartsWithFact);

    return isTaskOutsideChartRange({
        taskStartDate,
        taskEndDate,
        chartStartDate,
        chartEndDate,
    });
};

export const getTaskClassByFeatureAccess = (doesNotHaveFeatureAccess: TFeatureGuard) => {
    let result = '';
    if (doesNotHaveFeatureAccess('KSG_GANTT_LINK_ADD')) result += ' cant-link-add';
    if (doesNotHaveFeatureAccess('KSG_GANTT_TASK_DRAG')) result += ' cant-task-drag';
    if (doesNotHaveFeatureAccess('KSG_GANTT_TASK_OPEN')) result += ' cant-task-open';

    return result;
};

export const getLinkClassByFeatureAccess = (doesNotHaveFeatureAccess: TFeatureGuard) => {
    let result = '';
    if (doesNotHaveFeatureAccess('KSG_GANTT_LINK_DELETE')) result += ' cant-link-delete';

    return result;
};
