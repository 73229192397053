import { Stack, Tab } from '@mui/material'
import { isNumber } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IIntegrationStatus } from '../../../../../api/works/works.def'
import { useCollectionOfFactOfAct } from '../../../../../pages/DHTGant/model/useCollectionOfFactOfAct'
import { Nullable } from '../../../../../shared/def'
import { handleChangeHideFilled } from '../../../../../store/slices/filtersSlice'
import { useAppDispatch } from '../../../../../store/store'
import { TTabValues, tabsData } from './ControlPanel.config'
import { IBasePanelProps, TFilterKey, TFilters } from './ControlPanel.def'
import {
    FactGatheringPercentage,
    FilterButton,
    FilterIcon,
    FilterStack,
    FilterText,
    Period,
    StyledTabs,
} from './ControlPanel.styles'
import { getDateAsString } from './ControlPanel.utils'
import { FiltersMenu } from './FiltersMenu'

type TProps = {
    integrationStatus: IIntegrationStatus
}

const ActOfClosingPanel: React.FC<TProps> = ({ integrationStatus }) => {
    const [filtersAnchor, setFiltersAnchor] = useState<Nullable<HTMLButtonElement>>(null)
    const [filters, setFilters] = useState<TFilters>({
        hideFilled: false,
        visibleCost: false,
    })
    const dispatch = useAppDispatch()
    const isFiltersActive = Object.values(filters).some((value) => value)

    const startDate = getDateAsString(integrationStatus?.startDate)
    const endDate = getDateAsString(integrationStatus?.endDate)
    const { projectId } = useParams()

    const isVisiblePeriod = startDate && endDate

    const { percentage } = useCollectionOfFactOfAct()

    useEffect(() => {
        return () => {
            dispatch(handleChangeHideFilled(false))
        }
    }, [])

    const handleFiltersAnchorSet = (event: React.MouseEvent<HTMLButtonElement>) => {
        setFiltersAnchor(event.currentTarget)
    }

    const updateFilters = (prop: TFilterKey) => (value: boolean) => {
        setFilters((prev) => ({ ...prev, [prop]: value }))

        if (prop === 'hideFilled') {
            dispatch(handleChangeHideFilled(value))
        }
    }

    return (
        <>
            {isVisiblePeriod && (
                <Period>
                    Период: {startDate} - {endDate}
                </Period>
            )}
            {isNumber(percentage) && (
                <FactGatheringPercentage>{`Сбор факта (${Math.floor(percentage)}%)`}</FactGatheringPercentage>
            )}

            <FilterButton onClick={handleFiltersAnchorSet}>
                <FilterStack>
                    <FilterText>Быстрые фильтры</FilterText>
                    <FilterIcon isActive={isFiltersActive} />
                </FilterStack>
            </FilterButton>
            <FiltersMenu
                projectId={projectId!}
                filters={filters}
                updateFilters={updateFilters}
                anchorEl={filtersAnchor}
                onClose={() => setFiltersAnchor(null)}
            />
        </>
    )
}

export const BasePanel: React.FC<IBasePanelProps> = ({ isKC6APage, integrationStatus }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { projectId } = useParams()
    const navigate = useNavigate()

    const selectedTabValue: TTabValues = isKC6APage ? 'kc_6a' : 'to_closing'

    const handleTabsChange = (_: SyntheticEvent<Element, Event>, value: TTabValues) => {
        if (value === 'kc_6a' && !integrationStatus.closedActsPresent) {
            enqueueSnackbar('КС-6A не сформирован, отсутствуют закрытые акты', { variant: 'error' })
            return
        }

        const paths: Record<TTabValues, string> = {
            kc_6a: `/ksg/kc6a/${projectId}?offer=on`,
            to_closing: `/ksg/${projectId}?offer=on`,
        }
        navigate(paths[value])
    }

    return (
        <Stack direction={'row'} rowGap={1} columnGap={2.5} flexWrap={'wrap'} alignItems={'center'}>
            <StyledTabs value={selectedTabValue} onChange={handleTabsChange}>
                {tabsData.map(({ label, value }) => {
                    return <Tab key={value} label={label} value={value} />
                })}
            </StyledTabs>

            {!isKC6APage && <ActOfClosingPanel integrationStatus={integrationStatus} />}
        </Stack>
    )
}
