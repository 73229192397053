import { BodyScrollEvent, IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { GanttStatic } from 'dhtmlx-gantt';
import { useEffect, useMemo, useRef, useState } from 'react';

import { getHeadersRes } from '@/api/ksg/ksg.def';
import { TGanttLink } from '@/api/relations/relations.def';

import { IColumnTemplate } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.def';
import { useUpdateCurrentTemplate } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.model';

import { req } from '@/pages/WorkManagment/api/api';

import { Nullable } from '@/shared/def';

import { ILocalGanttLink, WorkWithStringId } from './DHTGant.def';
import {
    addChildSplitTask,
    createIsChildExists,
    createIsParentExists,
    createTask,
    getScrollDirection,
} from './DHTGantOnlyTable.utils';

const AG_GRID_DISPLAYED_ROWS_COUNT = 7;

export const useGetGanttEntities = (works: WorkWithStringId[] | null, projectId: string) => {
    const [links, setLinks] = useState<ILocalGanttLink[]>([]);

    useEffect(() => {
        req.post(`/projects/${projectId}/dependencies/works/ksg/links`, {
            included: [],
        })
            .then(({ data }) => {
                const links = data.links as TGanttLink[];
                setLinks(() => links.map((link, index) => ({ ...link, id: index })));
            })
            .catch((err) => {
                console.log('error', err);
            });
    }, [projectId, works]);

    const tasks = useMemo(() => {
        if (!works) return [];
        const isParentExists = createIsParentExists(works);
        const isChildExists = createIsChildExists(works);

        const tasks = works?.map((work) =>
            createTask({
                work,
                isParentExists: isParentExists(work.id_parent),
                isChildrenExists: isChildExists(work.id as number),
            })
        );
        const newChilds = tasks
            .filter((task) => !!task.render)
            .map((task) => addChildSplitTask(task))
            .flat();
        newChilds.forEach((child) => tasks.push(child));
        return tasks;
    }, [works]);

    return {
        tasks,
        links,
    };
};

export const useGetProjectDates = (projectId: string) => {
    const [headerCPG, setHeaderCPG] = useState<getHeadersRes | null>(null);

    useEffect(() => {
        req.get(`/projects/${projectId}/headers`).then(({ data }) => {
            setHeaderCPG(() => data);
        });
    }, [projectId]);

    useEffect(() => {
        return () => {
            //resetLayout()
            setHeaderCPG(null);
        };
    }, [projectId]);

    const projectDates = useMemo(() => {
        if (!headerCPG) return null;

        const firstHeader = headerCPG?.allHeaders.at(0);
        const lastHeader = headerCPG?.allHeaders.at(-1);
        if (!firstHeader || !lastHeader) return null;

        // firstHeader?.month - 2 - добавляю дополнительный месяц в начале проекта, чтобы таски не прилипали к краю таблицы
        const start = new Date(firstHeader?.year, firstHeader?.month - 2, 1);
        const end = new Date(lastHeader?.year, lastHeader?.month + 1, 1);

        return {
            start,
            end,
        };
    }, [headerCPG]);

    return projectDates;
};

const findFirstVisibleRow = (grid?: AgGridReact) => {
    if (!grid) return 0;
    if (!grid?.api) return 0;

    const gridBody = document.querySelector('.ag-body-viewport') as HTMLElement;

    const renderedNodes = grid.api.getRenderedNodes();
    if (renderedNodes.length === 0) return 0;

    let firstVisibleRowIndex = -1;

    renderedNodes.forEach((rowNode: IRowNode) => {
        if (firstVisibleRowIndex !== -1) return;
        if (rowNode.rowTop! >= gridBody.scrollTop) {
            firstVisibleRowIndex = rowNode.rowIndex!;
        }
    });

    return firstVisibleRowIndex;
};

export const useScrollSync = (grid: AgGridReact, gantt: GanttStatic, isGanttInitialized: boolean) => {
    const firstVisibleRowIndex = useRef(findFirstVisibleRow(grid));
    const ganttPrevScrollTop = useRef(-1);
    const gridScrollTop = useRef(0);

    useEffect(() => {
        if (!grid) return;
        if (!isGanttInitialized) return;

        const handleScroll = (event: BodyScrollEvent) => {
            firstVisibleRowIndex.current = findFirstVisibleRow(grid);

            if (event.top === -1) return;
            gridScrollTop.current = event.top;
            gantt.scrollTo(null, event.top);
        };

        grid.api.addEventListener('bodyScroll', handleScroll);

        return () => {
            grid.api.removeEventListener('bodyScroll', handleScroll);
        };
    }, [isGanttInitialized, grid, gantt]);

    useEffect(() => {
        if (!grid) return;
        if (!isGanttInitialized) return;

        const ganttScrollEvent = gantt.attachEvent('onGanttScroll', () => {
            const top = gantt.getScrollState().y;

            const direction = getScrollDirection(top, ganttPrevScrollTop.current);

            ganttPrevScrollTop.current = top;

            if (gridScrollTop.current === top || direction === 'none') return;

            const rowCount = grid.api.getDisplayedRowCount();

            const isStart = direction === 'up' && firstVisibleRowIndex.current === 0;
            const isEnd =
                direction === 'down' && firstVisibleRowIndex.current === rowCount - AG_GRID_DISPLAYED_ROWS_COUNT;

            if (isStart || isEnd) return;

            const calculateRowIndex = () =>
                direction === 'up' ? firstVisibleRowIndex.current - 1 : firstVisibleRowIndex.current + 1;
            firstVisibleRowIndex.current = calculateRowIndex();

            grid.api.ensureIndexVisible(firstVisibleRowIndex.current, 'top');
        });

        return () => {
            gantt.detachEvent(ganttScrollEvent);
        };
    }, [isGanttInitialized, gantt, grid]);
};

export const useGanttResize = (gantt: GanttStatic) => {
    const { update: updateCurrentTemplate } = useUpdateCurrentTemplate();

    const startWidth = useRef(0);

    const handleGridResizeStart = () => {
        const ganttContainer = document.getElementById('gantt') as HTMLDivElement;
        const currentWidth = ganttContainer.clientWidth;
        startWidth.current = currentWidth;
    };

    const handleGridResize = () => {
        const ganttContainer = document.getElementById('gantt') as HTMLDivElement;
        const maxWidth = Math.floor(window.innerWidth * 0.7);
        const minWidth = Math.floor(window.innerWidth * 0.2);

        const getMouseCursorPosition = (mouseEvent: MouseEvent) => {
            let mouseCoordX = mouseEvent.clientX - ganttContainer.offsetLeft;
            if (ganttContainer) {
                let newWidth = Math.floor(startWidth.current - mouseCoordX);

                if (newWidth > maxWidth) {
                    newWidth = maxWidth;
                } else {
                    if (newWidth < minWidth) {
                        newWidth = minWidth;
                    }
                }
                ganttContainer.style.width = newWidth + 'px';
                startWidth.current = newWidth;
            }
            document.removeEventListener('mousemove', getMouseCursorPosition);
        };

        document.addEventListener('mousemove', getMouseCursorPosition);
        gantt.setSizes();
    };

    const handleGridResizeEnd = () => {
        const ganttContainer = document.getElementById('gantt') as HTMLDivElement;
        const width = ganttContainer.clientWidth;

        updateCurrentTemplate({
            ganttSeparatorPosition: width,
        });
    };

    return {
        onGridResizeStart: handleGridResizeStart,
        onGridResize: handleGridResize,
        onGridResizeEnd: handleGridResizeEnd,
    };
};

// TODO:
// 1. Вынести в отдельный файл
const DEFAULT_GANTT_TABLE_WIDTH_FOR_DISPATCHER = 1200;
const DEFAULT_GANTT_TABLE_WIDTH = 800;

export const useGetGanttWidth = (currentTemplate: Nullable<IColumnTemplate>) => {
    const ganttWidth = useMemo(() => {
        if (!currentTemplate) return DEFAULT_GANTT_TABLE_WIDTH;
        if (currentTemplate?.name === 'Диспетчер' && currentTemplate.isCommon)
            return DEFAULT_GANTT_TABLE_WIDTH_FOR_DISPATCHER;
        if (!currentTemplate?.ganttSeparatorPosition) return DEFAULT_GANTT_TABLE_WIDTH;

        return currentTemplate.ganttSeparatorPosition;
    }, [currentTemplate]);

    return ganttWidth;
};
