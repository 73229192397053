import { isAxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { TGanttLinkModificationResponse } from '../../../../api/relations/relations.def'
import { isTooManyRequests } from '../../../../shared/utils'
import { onEditAgKsg } from '../../../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { useAppDispatch } from '../../../../store/store'
import { bindLinkMutation, updateManualPlanningMutation } from '../../DHTGantOnlyTable.api'
import { deleteLinksBetweenTasks } from '../../DHTGantOnlyTable.utils'
import { IGanttLinkByFactRestrictionDialogProps } from './GanttLinkByFactRestrictionDialog.def'
import {
    BaseButton,
    BoldText,
    Buttons,
    CancelButton,
    Container,
    DialogWindow,
    Text,
} from './GanttLinkByFactRestrictionDialog.styles'
import { calculateGanttLinkLag } from './GanttLinkByFactRestrictionDialog.utils'

export const GanttLinkByFactRestrictionDialog: React.FC<IGanttLinkByFactRestrictionDialogProps> = ({
    projectId,
    data,
    gantt,
    onClose,
    setOverlay,
}) => {
    const sourceTaskName = data?.sourceTask.workName
    const targetTaskName = data?.targetTask.workName
    const targetTaskId = data?.targetTask.id as number
    const linkPayload = data?.linkPayload
    const dispatch = useAppDispatch()

    const { enqueueSnackbar } = useSnackbar()

    const setLag = async () => {
        setOverlay('Устанавливаем связи')
        try {
            const bindLinkResponse = await bindLinkMutation(projectId, {
                ...linkPayload!,
                lag: calculateGanttLinkLag(linkPayload?.type!, {
                    sourceStartDate: data?.sourceTask.start_date!,
                    sourceEndDate: data?.sourceTask.end_date!,
                    targetStartDate: data?.targetTask.start_date!,
                    targetEndDate: data?.targetTask.end_date!,
                }),
            })
            const bindLinkData = bindLinkResponse.data as TGanttLinkModificationResponse
            deleteLinksBetweenTasks(gantt)({
                source: linkPayload?.source!,
                target: linkPayload?.target!,
            })
            gantt.addLink({
                id: Date.now(),
                canAdd: true,
                ...linkPayload,
            })

            if (bindLinkData.updatedWorks.length > 0) {
                dispatch(onEditAgKsg(bindLinkData.updatedWorks))
            }
        } catch (error) {
            console.log('error', error)
            if (!isAxiosError(error)) return
            if (isTooManyRequests(error)) return

            const DEFAULT_MESSAGE = 'Произошла ошибка при добавлении связи'
            enqueueSnackbar(DEFAULT_MESSAGE, {
                variant: 'error',
            })
        } finally {
            setOverlay(null)
            onClose()
        }
    }

    const setManualPlanning = async () => {
        setOverlay('Устанавливаем связи')
        try {
            await updateManualPlanningMutation(projectId, targetTaskId, true)

            const bindLinkResponse = await bindLinkMutation(projectId, linkPayload!)
            const bindLinkData = bindLinkResponse.data as TGanttLinkModificationResponse
            deleteLinksBetweenTasks(gantt)({
                source: linkPayload?.source!,
                target: linkPayload?.target!,
            })
            gantt.addLink({
                id: Date.now(),
                canAdd: true,
                ...linkPayload,
            })

            if (bindLinkData.updatedWorks.length > 0) {
                dispatch(onEditAgKsg(bindLinkData.updatedWorks))
            }
        } catch (error) {
            console.log('error', error)
            if (!isAxiosError(error)) return
            if (isTooManyRequests(error)) return

            const DEFAULT_MESSAGE = 'Произошла ошибка при добавлении связи'
            enqueueSnackbar(DEFAULT_MESSAGE, {
                variant: 'error',
            })
        } finally {
            setOverlay(null)
            onClose()
        }
    }

    return (
        <DialogWindow open={Boolean(data)} onClose={onClose}>
            <Container>
                <Text>
                    Вы хотите установить связь между задачей <BoldText>{sourceTaskName}</BoldText> и задачей{' '}
                    <BoldText>{targetTaskName}</BoldText>. Установить эту связь нельзя из-за существующего факта.
                </Text>
                <Buttons>
                    <CancelButton onClick={onClose}>Отменить действие</CancelButton>
                    <BaseButton onClick={setLag}>Установить запаздывание/опережение</BaseButton>
                    <BaseButton onClick={setManualPlanning}>Установить параметр "Ручное планирование"</BaseButton>
                </Buttons>
            </Container>
        </DialogWindow>
    )
}
