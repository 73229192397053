import { Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import type { AxiosError } from 'axios';
import { isAxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { Dispatch, Fragment, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { Work } from '@/api/ksg/ksg.def';
import { TGanttLinkModificationResponse } from '@/api/relations/relations.def';

import { useOpenDeleteZeroValueFactDialog } from '@/components/DeleteZeroValueFactDialog';

import { usePermissionsByRole } from '@/hooks/usePermissionsByRole';

import { FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';
import { LoadingOverlay } from '@/pages/WorkManagment/components/AgGridService/components/LoadingOverlay';

import { GanttChartSearchParams } from '@/shared/constants/gantt';
import { isShiftErrorsData } from '@/shared/guards/error.guards';
import { isTooManyRequests } from '@/shared/utils';
import { ShiftErrorCode, isOperationalDatesChangesBlockedByZeroFact } from '@/shared/utils/error.utils';

import { agGridKsgSelector, onEditAgKsg } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { templatesSelector } from '@/store/slices/templatesSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { ILibGanttLink, ITask, TGanttLinkPayload, TLocalGanttLinkId } from './DHTGant.def';
import { bindLinkMutation, unbindLinkMutation, updateOperationDatesMutation } from './DHTGantOnlyTable.api';
import {
    useGanttResize,
    useGetGanttEntities,
    useGetGanttWidth,
    useGetProjectDates,
    useScrollSync,
} from './DHTGantOnlyTable.model';
import {
    addLayers,
    deleteLinkPermanently,
    deleteLinksBetweenTasks,
    formatDateToString,
    getLinkClassByFeatureAccess,
    getTaskClassByFeatureAccess,
} from './DHTGantOnlyTable.utils';
import { GanttLinkByFactRestrictionDialog } from './components/GanttLinkByFactRestrictionDialog';
import { GanttLinkByFactRestrictionDialogData } from './components/GanttLinkByFactRestrictionDialog/GanttLinkByFactRestrictionDialog.def';
import { GanttLinkDialog } from './components/GanttLinkDialog';
import { GanttSplitDialog } from './components/GanttSplitDialog/GanttSplitDialog';
import { setGanttConfig } from './config/ganttConfig';
import { getGanttEventHandlers } from './config/ganttEvents';
import './customStylesOverrides.scss';
import { gantt } from './dhtmlxgantt';
import './dhtmlxgantt.css';

interface IGanttTableProps {
    filteredWorks: Work[] | null;
    grid: AgGridReact;
    rowHeights: {
        [key: number]: number;
    };
    setRowHeights: Dispatch<
        SetStateAction<{
            [key: number]: number;
        }>
    >;
}

export const GanttTable: React.FC<IGanttTableProps> = ({ filteredWorks, grid, rowHeights, setRowHeights }) => {
    const { projectId } = useParams();
    const [urlSearchParams] = useSearchParams();
    const ganttRef = useRef(null) as RefObject<HTMLDivElement>;
    const dispatch = useAppDispatch();
    const [overlayTitle, setOverlayTitle] = useState<string | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedTask, setSelectedTask] = useState<ITask | null>(null);
    const [splitedTask, setSplitedTask] = useState<ITask | null>(null);
    const [toggleTaskLoaded, setToggleTaskLoaded] = useState(false);
    const { rowHeight } = useTypedSelector(agGridKsgSelector);
    const isCriticalPathEnabled = urlSearchParams.has(GanttChartSearchParams.CriticalPath);
    const isVisibleGanttFact = urlSearchParams.has(GanttChartSearchParams.Fact);
    const projectDates = useGetProjectDates(projectId as string);
    const { tasks, links } = useGetGanttEntities(filteredWorks, projectId as string);
    const { currentTemplate } = useTypedSelector(templatesSelector);
    const openDeleteZeroValueFactDialog = useOpenDeleteZeroValueFactDialog();

    const ganttWidth = useGetGanttWidth(currentTemplate);

    const isGanttInitialized = useRef(false);

    const { doesNotHaveFeatureAccess } = usePermissionsByRole();

    const { onGridResizeStart, onGridResize, onGridResizeEnd } = useGanttResize(gantt);

    const { t } = useTranslation('mutations');

    const [ganttLinkByFactRestrictionDialogData, setGanttLinkByFactRestrictionDialogData] =
        useState<GanttLinkByFactRestrictionDialogData | null>(null);

    // Синхронизация скролла
    useScrollSync(grid, gantt, isGanttInitialized.current);

    // Инициализация ганта
    useEffect(() => {
        if (!ganttRef?.current) return;
        if (!projectDates) return;

        const events = getGanttEventHandlers(gantt, {
            onBeforeLinkAdd: handleBeforeLinkAdd,
            onBeforeLinkDelete: handleBeforeLinkDelete,
            onBeforeTaskDrag: handleBeforeTaskDrag,
            onAfterTaskDrag: handleAfterTaskDrag,
            onTaskDblClick: handleTaskDblClick,
            onTaskClick: handleTaskClick,
            onTaskLoaded: handleTaskLoaded,
            onGridResizeStart: onGridResizeStart,
            onGridResize: onGridResize,
            onGridResizeEnd: onGridResizeEnd,
        });

        setGanttConfig(gantt, {
            projectDates,
            highlightCriticalPath: isCriticalPathEnabled,
            rowHeight,
            taskClass: getTaskClassByFeatureAccess(doesNotHaveFeatureAccess),
            linkClass: getLinkClassByFeatureAccess(doesNotHaveFeatureAccess),
        });
        gantt.init('gantt', projectDates.start, projectDates.end);
        addLayers(gantt, projectDates);
        gantt.parse({
            tasks,
            links,
        });

        isGanttInitialized.current = true;

        return () => {
            gantt.clearAll();
            gantt.resetLayout();

            for (const event of events) {
                gantt.detachEvent(event);
            }
        };
    }, [projectId, currentTemplate, projectDates, ganttRef, isCriticalPathEnabled, urlSearchParams, rowHeight]);

    useEffect(() => {
        if (!isGanttInitialized.current) return;

        gantt.clearAll();
        gantt.parse({
            tasks,
            links,
        });
        setGanttScrollTop();
    }, [tasks, links]);

    // Обновление высоты каждой строки
    useEffect(() => {
        if (!ganttRef.current || !gantt.getTaskCount()) return;
        Object.keys(rowHeights).forEach((index) => {
            const task = gantt.getTaskByIndex(Number(index));
            const height = rowHeights[Number(index)];
            if (task) {
                task.height = height;
                task.row_height = height;
                gantt.updateTask(task.id, task);
            }
        });
        gantt.render();
    }, [rowHeights, ganttRef, toggleTaskLoaded]);

    // Обновление высоты всех строк
    useEffect(() => {
        if (!ganttRef.current) return;
        gantt.eachTask((task) => {
            if (task) {
                task.height = rowHeight;
                task.row_height = rowHeight;
                gantt.updateTask(task.id, task);
            }
        });
        gantt.render();
        setRowHeights({});
    }, [rowHeight, ganttRef, toggleTaskLoaded]);

    const setGanttScrollTop = () => {
        const verticalRange = grid.api.getVerticalPixelRange();
        gantt.scrollTo(null, verticalRange.top);
    };

    const setOverlay = (title: string | null) => setOverlayTitle(() => title);

    const updateTaskOperationDates = async (task: ITask) => {
        const operationalStartDate = formatDateToString(task.start_date);
        const operationalEndDate = formatDateToString(task.end_date);

        if (operationalStartDate === null || operationalEndDate === null) {
            setTimeout(gantt.undo, 500);
            return;
        }

        try {
            setOverlay('Сохраняем новые даты оперативного плана...');
            const { data } = await updateOperationDatesMutation(projectId!, task.id as number, {
                operationalStartDate,
                operationalEndDate,
            });

            const works = data.data as Work[];
            // batchUpdateTasksDates(gantt)(works)
            // grid.api.applyTransaction({ update: works })
            dispatch(onEditAgKsg(works));
        } catch (error: AxiosError | unknown) {
            console.log('updateTaskOperationDates Error', error);
            if (!isAxiosError(error)) return;
            if (isTooManyRequests(error)) return;

            const errorData = error.response?.data;
            if (isShiftErrorsData(errorData)) {
                if (isOperationalDatesChangesBlockedByZeroFact(errorData.message)) {
                    setTimeout(gantt.undo, 500);
                    openDeleteZeroValueFactDialog(errorData.zeroFactBlockers);
                    return;
                }
            }

            const DEFAULT_MESSAGE = 'Ошибка при изменение дат оперативного плана';
            const message = t(`bind_errors.${errorData}`, DEFAULT_MESSAGE);
            enqueueSnackbar(message, {
                variant: 'error',
            });
            setTimeout(gantt.undo, 500);
        } finally {
            setOverlay(null);
        }
    };

    const handleTaskDblClick = (taskId: TLocalGanttLinkId) => {
        if (doesNotHaveFeatureAccess('KSG_GANTT_TASK_OPEN')) return false;

        const task = gantt.getTask(taskId) as ITask;
        if (!task) return false;
        if (task?.hasChildren && !task.render) return false;
        if (Number.isNaN(Number(taskId))) {
            if (task.parent) {
                const splitedTask = gantt.getTask(task.parent) as ITask;
                if (splitedTask) {
                    setSelectedTask(() => splitedTask);
                }
            }
        } else {
            setSelectedTask(() => task);
        }
    };

    const handleTaskClick = (taskId: TLocalGanttLinkId, e: MouseEvent) => {
        const task = gantt.getTask(taskId) as ITask;
        if (urlSearchParams.has(GanttChartSearchParams.Split) && !task.hasChildren && !task.volumeDoneFact) {
            setSplitedTask(task);
        }
        return true;
    };

    const handleGanttLinkDialogClose = () => setSelectedTask(null);

    const handleGanttSplitDialogClose = () => setSplitedTask(null);

    const handleGanttLinkByFactRestrictionDialogClose = () => setGanttLinkByFactRestrictionDialogData(null);

    const handleBeforeTaskDrag = (id: number, mode: string, e: MouseEvent) => {
        if (doesNotHaveFeatureAccess('KSG_GANTT_TASK_DRAG')) return false;
        return true;
    };

    const handleAfterTaskDrag = (taskId: number) => {
        if (doesNotHaveFeatureAccess('KSG_GANTT_TASK_DRAG')) return false;

        const task = gantt.getTask(taskId) as ITask;
        if (!task) return;

        // if (isTaskDateRangeOutOfFact(task)) {
        //     const message = t(`bind_errors.operational_dates_changes_blocked_by_fact`);
        //     enqueueSnackbar(message, {
        //         variant: 'error',
        //     });
        //     setTimeout(gantt.undo, 200);
        //     return;
        // }

        updateTaskOperationDates(task);
    };

    const handleBeforeLinkAdd = (linkId: TLocalGanttLinkId, link: ILibGanttLink & { canAdd?: boolean }): boolean => {
        if (doesNotHaveFeatureAccess('KSG_GANTT_LINK_ADD')) return false;

        const targetTask = gantt.getTask(link.target);
        if (targetTask && targetTask?.hasChildren) return false;

        if (link?.canAdd) return true;

        const payload: TGanttLinkPayload = {
            source: Number(link.source),
            target: Number(link.target),
            type: link.type,
            lag: link.lag ? link.lag : 0,
        };
        bindLink(linkId, payload);
        return false;
    };

    const handleBeforeLinkDelete = (
        linkId: TLocalGanttLinkId,
        link: ILibGanttLink & { canDelete?: boolean }
    ): boolean => {
        if (doesNotHaveFeatureAccess('KSG_GANTT_LINK_DELETE')) return false;
        if (link?.canDelete) return true;

        const payload: TGanttLinkPayload = {
            source: Number(link.source),
            target: Number(link.target),
            type: link.type,
            lag: link.lag ? link.lag : 0,
        };
        unbindLink(linkId, payload);
        return false;
    };

    const handleTaskLoaded = () => setToggleTaskLoaded(!toggleTaskLoaded);

    const bindLink = async (linkId: TLocalGanttLinkId, payload: TGanttLinkPayload) => {
        setOverlay('Устанавливаем связи');
        try {
            const response = await bindLinkMutation(projectId!, payload);
            const data = response.data as TGanttLinkModificationResponse;
            deleteLinksBetweenTasks(gantt)({
                source: payload.source,
                target: payload.target,
            });
            gantt.addLink({
                id: linkId,
                canAdd: true,
                ...payload,
            });

            if (data.updatedWorks.length > 0) {
                //batchUpdateTasksDates(gantt)(data.updatedWorks)
                //grid.api.applyTransaction({ update: data.updatedWorks })
                dispatch(onEditAgKsg(data.updatedWorks));
            }

            return data;
        } catch (error) {
            console.log('bindLink Error', error);
            if (!isAxiosError(error)) return;
            if (isTooManyRequests(error)) return;

            const errorData = error.response?.data;
            if (isShiftErrorsData(errorData)) {
                const shiftErrorsHandlingMap: {
                    [key: string]: () => void;
                } = {
                    [ShiftErrorCode.OPERATIONAL_DATES_CHANGES_BLOCKED_BY_ZERO_FACT]: () => {
                        openDeleteZeroValueFactDialog(errorData.zeroFactBlockers);
                    },
                    [ShiftErrorCode.OPERATIONAL_DATES_CHANGES_BLOCKED_BY_FACT]: () => {
                        const sourceTask = gantt.getTask(payload.source) as ITask;
                        const targetTask = gantt.getTask(payload.target) as ITask;

                        setGanttLinkByFactRestrictionDialogData(() => ({
                            sourceTask,
                            targetTask,
                            linkPayload: payload,
                        }));
                    },
                };
                const handle = shiftErrorsHandlingMap[errorData.message] ?? null;
                if (handle) {
                    handle();
                    return;
                }
            }

            const DEFAULT_MESSAGE = 'Произошла ошибка при добавлении связи';
            enqueueSnackbar(DEFAULT_MESSAGE, {
                variant: 'error',
            });
        } finally {
            setOverlay(null);
        }
    };

    const unbindLink = async (linkId: TLocalGanttLinkId, payload: TGanttLinkPayload) => {
        setOverlay('Удаляем связь');
        try {
            const response = await unbindLinkMutation(projectId!, payload);
            const data = response.data as TGanttLinkModificationResponse;
            deleteLinkPermanently(gantt, linkId);

            if (data.updatedWorks.length > 0) {
                // batchUpdateTasksDates(gantt)(data.updatedWorks)
                // grid.api.applyTransaction({ update: data.updatedWorks })
                dispatch(onEditAgKsg(data.updatedWorks));
            }
            return data;
        } catch (error) {
            console.log('unbindLink Error', error);
            if (!isAxiosError(error)) return;
            if (isTooManyRequests(error)) return;

            const errorData = error.response?.data;
            if (isShiftErrorsData(errorData)) {
                if (isOperationalDatesChangesBlockedByZeroFact(errorData.message)) {
                    openDeleteZeroValueFactDialog(errorData.zeroFactBlockers);
                    return;
                }
            }

            enqueueSnackbar('Произошла ошибка при удалении связи', {
                variant: 'error',
            });
        } finally {
            setOverlay(null);
        }
    };

    return (
        <Fragment>
            <GanttLinkDialog
                tasks={tasks}
                gantt={gantt}
                grid={grid}
                selectedTaskId={selectedTask?.id ?? null}
                taskName={selectedTask?.workName}
                setOverlayTitle={setOverlayTitle}
                onClose={handleGanttLinkDialogClose}
                setGanttLinkByFactRestrictionDialogData={setGanttLinkByFactRestrictionDialogData}
            />
            <GanttSplitDialog
                tasks={tasks}
                gantt={gantt}
                grid={grid}
                splitedTask={splitedTask ?? null}
                taskName={splitedTask?.workName}
                setOverlayTitle={setOverlayTitle}
                onClose={handleGanttSplitDialogClose}
            />
            <GanttLinkByFactRestrictionDialog
                projectId={projectId!}
                gantt={gantt}
                data={ganttLinkByFactRestrictionDialogData!}
                onClose={handleGanttLinkByFactRestrictionDialogClose}
                setOverlay={setOverlay}
            />
            <LoadingOverlay
                key='overlay'
                open={!!overlayTitle}
                transitionDuration={0}
                sx={{
                    zIndex: 9999,
                    height: 'calc(100% - 125px)',
                    background: 'rgba(255,255,255, 0.4)',
                }}
            >
                <FlexRowWrapper
                    gap={1}
                    bottom={20}
                    right={20}
                    position={'absolute'}
                    bgcolor={'white'}
                    px={2}
                    py={1}
                >
                    <span
                        className='loader'
                        style={{ width: 24, height: 24, zIndex: 999 }}
                    ></span>
                    <Typography>{overlayTitle && overlayTitle}</Typography>
                </FlexRowWrapper>
            </LoadingOverlay>
            <div
                id='gantt'
                ref={ganttRef}
                style={
                    {
                        '--task-progress-color': `${isVisibleGanttFact ? '#C4B5FD' : 'transparent'}`,
                        height: '100%',
                        width: ganttWidth,
                        minWidth: '20%',
                        maxWidth: '70%',
                    } as React.CSSProperties
                }
            />
        </Fragment>
    );
};
