import { req } from '@/pages/WorkManagment/api/api'

import { TGanttLinkPayload, TUpdateOperationDatesMutationPayload } from './DHTGant.def'

export const updateManualPlanningMutation = async (projectId: string, workId: number, manualPlanning: boolean) => {
    return await req.patch(`/projects/${projectId}/dependencies/works/ksg/${workId}/planning-mode`, {
        manualPlanning
    })
}

export const bindLinkMutation = async (projectId: string, payload: TGanttLinkPayload) => {
    return await req.post(`/projects/${projectId}/dependencies/works/ksg/bind/v2`, payload)
}

export const unbindLinkMutation = async (projectId: string, payload: TGanttLinkPayload) => {
    return await req.post(`/projects/${projectId}/dependencies/works/ksg/unbind`, payload)
}

export const updateOperationDatesMutation = async (
    projectId: string,
    workId: number,
    payload: TUpdateOperationDatesMutationPayload
) => {
    return await req.post(`/projects/${projectId}/dependencies/works/ksg/${workId}/move`, payload)
}
