import { Add, Autorenew, Edit, List } from '@mui/icons-material';
import { Box, Button, FormLabel, IconButton, Radio, Typography } from '@mui/material';
import { ColDef, ColumnState } from 'ag-grid-community';
import { useSnackbar } from 'notistack';
import { RefObject, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoMdTrash } from 'react-icons/io';
import { useNavigation, useParams } from 'react-router-dom';

import { useToggleSearchParam } from '@/hooks/useToggleSearchParam';

import { FlexColumnWrapper, FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';
import { req } from '@/pages/WorkManagment/api/api';
import { LoadingOverlay } from '@/pages/WorkManagment/components/AgGridService/components/LoadingOverlay';
import { ConfirmDialog } from '@/pages/WorkManagment/components/ConfirmDialog/ConfirmDialog';

import { StyledTooltip } from '@/shared/components/StyledTooltip';
import { IntegrationWithOfferSearchParam } from '@/shared/constants/integration-with-offer';
import { Nullable } from '@/shared/def';

import { agGridKsgSelector } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { drawersSelector } from '@/store/slices/drawersSlice';
import { integrationStatusSelector } from '@/store/slices/integrationStatusSlice';
import { setCurrentTemplate, templatesSelector } from '@/store/slices/templatesSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { theme } from '@/styles/theme';

import { OverflowableTypographyWithTooltip } from '../OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import {
    StyledDrawer,
    StyledDrawerContent,
    StyledDrawerInput,
    StyledDrawerTitle,
} from '../StyledDrawer/StyledDrawer.styles';
import { DEFAULT_TEMPLATE_NAME } from './ConfigureAgGridColDefTemplate.config';
import {
    IColumnTemplate,
    IConfigureTemplateListBtnProps,
    TPatternsListType,
} from './ConfigureAgGridColDefTemplate.def';
import { useUpdateDefaultTemplates } from './ConfigureAgGridColDefTemplate.model';
import {
    addDefaultTemplates,
    getCurrentColDefsState,
    getFilteredColumnsState,
    getSortedTemplates,
    hasDefaultTemplates,
    isPageWithDefaultTemplates,
} from './ConfigureAgGridColDefTemplate.service';
import { StyledIconBtn, StyledMenu, StyledMenuItem } from './ConfigureAgGridColDefTemplate.styles';
import { StyledColumnSettingsRow } from './StyledColumnSettingsRow/StyledColumnSettingsRow';

/**
 * Создает меню управления шаблонами колонок AgGrid на основе gridApi.
 * Генерирует доступные к управлению колонки на основании colDefs и colId.
 * Если у колонки в columnDefs отсутствует colId - в шаблон она не попадет
 */

type TMode = 'add' | 'edit';

export function ConfigureAgGridColDefTemplate({ type, excludeColIds }: IConfigureTemplateListBtnProps) {
    const { projectId } = useParams();
    const dispatch = useAppDispatch();
    const { isActive } = useToggleSearchParam(IntegrationWithOfferSearchParam);
    const integrationStatus = useTypedSelector(integrationStatusSelector);
    const isOfferActive = integrationStatus?.isImportFromOffer && isActive;
    const { enqueueSnackbar } = useSnackbar();
    const { AgGrid } = useTypedSelector(drawersSelector);
    const [openTemplateListMenu, setOpenTemplateListMenu] = useState<boolean>(false);
    const [openTemplateSettingsDrawer, setOpenTemplateSettingsDrawer] = useState<boolean>(false);
    const [templateList, setTemplatesList] = useState<IColumnTemplate[]>([]);
    const [refreshTemplateList, setRefreshTemplateList] = useState<boolean>(false);
    const [mode, setMode] = useState<Nullable<TMode>>(null);
    const [activeTemplate, setActiveTemplate] = useState<IColumnTemplate | null>(null);
    const [isConfirmCancelDialogOpen, setIsConfirmCancelDialogOpen] = useState<boolean>(false);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState<boolean>(false);
    const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
    const { isRefreshList, currentTemplate } = useTypedSelector(templatesSelector);
    const { rowHeight: globalRowHeight } = useTypedSelector(agGridKsgSelector);
    const [activeTemplateOverlay, setActiveTemplateOverlay] = useState({
        shown: true,
        message: 'Получаем данные...',
    });
    const navigation = useNavigation();
    const isPageLoading = navigation.state === 'loading';

    const sortedTemplates = getSortedTemplates(templateList, type as TPatternsListType);

    const btnRef = useRef() as RefObject<HTMLButtonElement>;

    const { control, getValues, formState, resetField, setValue } = useForm({
        mode: 'all',
        defaultValues: {
            name: '',
        },
    });

    const refreshTemplates = () => setRefreshTemplateList((prevState) => !prevState);

    useUpdateDefaultTemplates({
        type,
        templateList,
        isPageLoading,
        activeTemplate,
        updateActiveTemplate: (updatedActiveTemplate: IColumnTemplate) => {
            setActiveTemplate(updatedActiveTemplate);
            applyTemplateToAgGrid(updatedActiveTemplate);
        },
        updateTemplateList: (updatedList: IColumnTemplate[]) => {
            setTemplatesList((prev) => {
                const ids = updatedList.map((v) => v.id);
                return [...updatedList, ...prev.filter((v) => !ids.includes(v.id))];
            });
        },
    });

    useEffect(() => {
        refreshTemplates();
    }, [isRefreshList]);

    useEffect(() => {
        req.get(`/projects/${projectId}/patterns/list?type=${type}`)
            .then(async ({ data }) => {
                const templates = data.data as IColumnTemplate[];
                const activeTemplateFromData = data.data.find((template: IColumnTemplate) => template.isActive) ?? null;

                const shouldAddDefaultTemplates =
                    !isPageLoading &&
                    isPageWithDefaultTemplates(type) &&
                    !hasDefaultTemplates(type as TPatternsListType, templates);

                if (shouldAddDefaultTemplates) {
                    await addDefaultTemplates({
                        type: type as TPatternsListType,
                        projectId: projectId!,
                        hasActiveTemplate: Boolean(activeTemplateFromData),
                        refreshTemplates,
                        enqueueSnackbar,
                    });
                }

                const shouldSetDefaultTemplate =
                    !isPageLoading && isPageWithDefaultTemplates(type) && !activeTemplateFromData;

                if (shouldSetDefaultTemplate) {
                    const activeTemplate = data.data.find(
                        (template: IColumnTemplate) =>
                            template?.name === DEFAULT_TEMPLATE_NAME[type as TPatternsListType]
                    );
                    await req.post(`/projects/${projectId}/patterns/${activeTemplate?.id}/set`);
                    refreshTemplates();
                }

                if (activeTemplateFromData?.id !== activeTemplate?.id) {
                    setActiveTemplateOverlay((prevState) => ({
                        ...prevState,
                        message: 'Применяем настройки шаблона...',
                    }));
                    setActiveTemplate(() => activeTemplateFromData);
                    dispatch(setCurrentTemplate(activeTemplateFromData));
                }
                setTemplatesList(() => templates);
            })
            .catch((e) => {
                enqueueSnackbar('Не удалось загрузить список доступных шаблонов', {
                    variant: 'error',
                });
            });
    }, [refreshTemplateList, projectId, type]);

    useEffect(() => {
        if (!isOfferActive) return;
        if (!activeTemplate) return;

        setTimeout(() => applyTemplateToAgGrid(activeTemplate));
    }, [isOfferActive]);

    useEffect(() => {
        if (!!activeTemplate && AgGrid) {
            saveChosenActiveTemplateToServer(activeTemplate.id);
            setTimeout(() => applyTemplateToAgGrid(activeTemplate));
        }
        activeTemplate
            ? localStorage.setItem(
                  'activeTemplate',
                  JSON.stringify(getServerTemplateConvertedIntoAgGridColState(activeTemplate))
              )
            : localStorage.removeItem('activeTemplate');
    }, [activeTemplate, AgGrid, projectId, type]);

    useEffect(() => {
        activeTemplateOverlay.shown &&
            setTimeout(() => {
                resetOverlay();
            }, 1000);
    }, [activeTemplateOverlay]);

    useEffect(() => {
        setOpenTemplateSettingsDrawer(!!mode);
        mode === 'edit' && setValue('name', activeTemplate?.name || '', { shouldValidate: true });
        if (mode === null) {
            setIsConfirmCancelDialogOpen(() => false);
            setIsConfirmDeleteDialogOpen(() => false);
            setIsFormChanged(() => false);
            resetField('name');
        }
    }, [mode]);

    const applyTemplateToAgGrid = useCallback(
        (activeTemplate: IColumnTemplate) => {
            AgGrid?.columnApi?.applyColumnState({
                state: getServerTemplateConvertedIntoAgGridColState(activeTemplate) as ColumnState[],
            });
        },
        [AgGrid]
    );

    function saveChosenActiveTemplateToServer(templateID: number) {
        req.post(`/projects/${projectId}/patterns/${templateID}/set`).catch((e) => {
            enqueueSnackbar('Не удалось сохранить выбранный шаблон в качестве активного', { variant: 'error' });
        });
    }

    function resetOverlay() {
        setActiveTemplateOverlay(() => ({
            shown: false,
            message: '',
        }));
    }

    const createSubmitPayloadByMode = (mode: TMode) => {
        if (mode === 'add') {
            return {
                name: getValues('name'),
                type: type,
                isCommon: false,
                rowHeight: null,
                columns: getFilteredColumnsState(AgGrid).map((columnState) => ({
                    name: (columnState as ColumnState).colId,
                    pinned: (columnState as ColumnState).pinned,
                    hide: (columnState as ColumnState).hide,
                    width: (columnState as ColumnState).width,
                })),
                ganttSeparatorPosition: null,
                isActive: true,
                isGanttOn: false,
                isOwner: true,
                version: null,
            };
        }

        return {
            ...currentTemplate,
            name: getValues('name'),
            columns: getFilteredColumnsState(AgGrid).map((columnState) => ({
                name: (columnState as ColumnState).colId,
                pinned: (columnState as ColumnState).pinned,
                hide: (columnState as ColumnState).hide,
                width: (columnState as ColumnState).width,
            })),
        };
    };

    const getSubmitRequestInfoByMode = (mode: TMode) => {
        if (mode === 'add') {
            return {
                URL: `/projects/${projectId}/patterns/add`,
                successMessage: ' добавлен',
            };
        }

        return {
            URL: `/projects/${projectId}/patterns/${activeTemplate?.id}/update`,
            successMessage: ' изменен',
        };
    };

    function onSubmit() {
        const { URL, successMessage } = getSubmitRequestInfoByMode(mode!);
        const payload = createSubmitPayloadByMode(mode!);

        formState.isValid &&
            URL &&
            req
                .post(URL, payload)
                .then(({ data }) => {
                    if (mode === 'add') {
                        saveActiveTemplate(data);
                        refreshTemplates();
                        resetField('name');
                    }
                    if (mode === 'edit') {
                        setTemplatesList((prevState) =>
                            prevState.map((template) => {
                                if (activeTemplate && template.id === activeTemplate?.id) {
                                    return {
                                        ...template,
                                        columns: getFilteredColumnsState(AgGrid).map((columnState) => ({
                                            name: (columnState as ColumnState).colId,
                                            pinned: (columnState as ColumnState).pinned,
                                            hide: (columnState as ColumnState).hide,
                                            width: (columnState as ColumnState).width,
                                        })),
                                        name: getValues('name'),
                                    } as IColumnTemplate;
                                }
                                return template;
                            })
                        );
                        activeTemplate &&
                            setActiveTemplate((prevState) => {
                                const activeTemplate = {
                                    ...prevState,
                                    ...payload,
                                } as IColumnTemplate;
                                return activeTemplate;
                            });
                        dispatch(setCurrentTemplate(payload as IColumnTemplate));
                    }

                    enqueueSnackbar(successMessage, { variant: 'success' });
                    setOpenTemplateSettingsDrawer(() => false);
                    setTimeout(() => setMode(() => null), 200);
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка', { variant: 'error' });
                });
    }

    function getServerTemplateConvertedIntoAgGridColState(template: IColumnTemplate) {
        return (
            template?.columns.map((column) => ({
                colId: column.name,
                hide: column.hide,
                pinned: column.pinned,
            })) || []
        );
    }

    function saveActiveTemplate(template: IColumnTemplate, updateActiveTemplate?: boolean, showOverlay?: boolean) {
        if (showOverlay) {
            setActiveTemplateOverlay((prevState) => ({
                ...prevState,
                shown: true,
                message: 'Применяем настройки шаблона...',
            }));
        }

        updateActiveTemplate && setActiveTemplate(() => template);
        dispatch(setCurrentTemplate(template));
        req.post(`/projects/${projectId}/patterns/${template.id}/set`).catch((e) => {
            enqueueSnackbar('Ошибка', { variant: 'error' });
        });
    }

    const resetTemplate = async () => {
        if (['ksg', 'msg'].includes(type)) {
            const activeTemplate =
                templateList.find(
                    (template: IColumnTemplate) => template?.name === DEFAULT_TEMPLATE_NAME[type as TPatternsListType]
                ) ?? null;
            await req.post(`/projects/${projectId}/patterns/${activeTemplate?.id}/set`);
            setActiveTemplate(() => activeTemplate);
            return;
        }

        activeTemplate &&
            req
                .post(`/projects/${projectId}/patterns/reset`, {
                    type: type,
                })
                .then(() => {
                    enqueueSnackbar('Шаблон успешно сброшен', { variant: 'success' });
                    setActiveTemplate(null);
                    dispatch(setCurrentTemplate(null));

                    AgGrid?.columnApi.resetColumnState();
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка', { variant: 'error' });
                });
    };

    function cancel() {
        setOpenTemplateSettingsDrawer(() => false);
        setTimeout(() => {
            setMode(null);
            activeTemplate
                ? AgGrid?.columnApi.applyColumnState({
                      state: getServerTemplateConvertedIntoAgGridColState(activeTemplate) as ColumnState[],
                  })
                : AgGrid?.columnApi.resetColumnState();
        });
    }

    function deleteTemplate() {
        activeTemplate &&
            req
                .delete(`/projects/${projectId}/patterns/${activeTemplate.id}/delete`)
                .then(() => {
                    setOpenTemplateSettingsDrawer(() => false);
                    setOpenTemplateListMenu(() => false);
                    setMode(null);
                    enqueueSnackbar('Шаблон успешно удален', { variant: 'success' });
                    AgGrid?.columnApi.resetColumnState();
                    refreshTemplates();
                    resetField('name');
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка', { variant: 'error' });
                });
    }

    function leaveWithConfirm() {
        if (isFormChanged) {
            setIsConfirmCancelDialogOpen(() => true);
        } else {
            cancel();
        }
    }

    const tableColumns = getCurrentColDefsState(AgGrid, excludeColIds) ?? [];

    return (
        <Box>
            <LoadingOverlay
                key='overlay'
                open={activeTemplateOverlay.shown}
                transitionDuration={0}
                sx={{ zIndex: 999 }}
            >
                <Typography>{activeTemplateOverlay.message}</Typography>
            </LoadingOverlay>

            {/* Кнопка "Шаблоны отображения таблицы" */}
            <StyledTooltip title='Шаблоны отображения таблицы'>
                <StyledIconBtn
                    ref={btnRef}
                    color={'primary'}
                    onClick={(e: SyntheticEvent) => {
                        setOpenTemplateListMenu((prevState) => !prevState);
                    }}
                >
                    <List />
                </StyledIconBtn>
            </StyledTooltip>

            {/* Главное меню */}
            <StyledMenu
                anchorEl={btnRef?.current}
                open={openTemplateListMenu}
                onClose={(e, r) => setOpenTemplateListMenu(() => false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <FlexColumnWrapper
                    p={0}
                    gap={0}
                >
                    <StyledMenuItem
                        onClick={resetTemplate}
                        sx={{ position: 'sticky' }}
                    >
                        <Autorenew />
                        <Typography>Сбросить</Typography>
                    </StyledMenuItem>
                    <FlexColumnWrapper
                        p={0}
                        gap={0}
                        maxHeight={520}
                        sx={{ overflowY: 'auto', overflowX: 'hidden' }}
                    >
                        {sortedTemplates?.map((template) => {
                            return (
                                <FormLabel
                                    key={template.id}
                                    disabled={activeTemplateOverlay.shown}
                                >
                                    <StyledMenuItem
                                        key={template.id}
                                        sx={{
                                            ...(template.id === activeTemplate?.id && {
                                                bgcolor: theme.palette.bg.shades,
                                            }),
                                        }}
                                        disableRipple
                                    >
                                        <Radio
                                            checked={template.id === activeTemplate?.id}
                                            sx={{ p: 0, color: `${theme.palette.secondary.light} !important` }}
                                            disableRipple
                                            onChange={(e, checked) =>
                                                checked && saveActiveTemplate(template, true, true)
                                            }
                                            disabled={activeTemplateOverlay.shown}
                                        />
                                        <OverflowableTypographyWithTooltip
                                            TypographyProps={{
                                                sx: {
                                                    display: 'block !important',
                                                    background: 'none !important',
                                                },
                                            }}
                                            maxRows={1}
                                        >
                                            {template.name}
                                        </OverflowableTypographyWithTooltip>
                                        {!template.isCommon && template.id === activeTemplate?.id && (
                                            <IconButton
                                                sx={{ ml: 'auto' }}
                                                onClick={(e) => setMode(() => 'edit')}
                                            >
                                                <Edit />
                                            </IconButton>
                                        )}
                                    </StyledMenuItem>
                                </FormLabel>
                            );
                        })}
                    </FlexColumnWrapper>
                    <StyledMenuItem
                        onClick={(e) => {
                            setMode(() => 'add');
                        }}
                    >
                        <Add />
                        <Typography>Добавить</Typography>
                    </StyledMenuItem>
                </FlexColumnWrapper>
            </StyledMenu>

            {/* Драйвер для создания шаблона таблицы */}
            <StyledDrawer
                open={openTemplateSettingsDrawer}
                onClose={leaveWithConfirm}
                sx={{ zIndex: 999999 }}
            >
                <StyledDrawerTitle>Создать шаблон таблицы</StyledDrawerTitle>
                <FlexRowWrapper p={2.5}>
                    <Controller
                        name='name'
                        control={control}
                        defaultValue=''
                        rules={{
                            required: {
                                value: true,
                                message: 'Обязательное поле',
                            },
                            maxLength: {
                                value: 96,
                                message: 'Не более 96 символов',
                            },
                        }}
                        render={({ field, fieldState }) => {
                            return (
                                <StyledDrawerInput
                                    placeholder='Введите наименование шаблона'
                                    {...field}
                                    value={field.value}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                />
                            );
                        }}
                    />
                </FlexRowWrapper>
                <StyledDrawerContent
                    sx={{ padding: `${theme.spacing(0, 2.5)} !important`, gap: `${theme.spacing(1)} !important` }}
                >
                    {tableColumns.map((colDef) => {
                        return (
                            <StyledColumnSettingsRow
                                key={colDef?.colId}
                                colDef={colDef as ColDef}
                                AgGrid={AgGrid}
                                setIsFormChanged={setIsFormChanged}
                            />
                        );
                    })}
                </StyledDrawerContent>
                <FlexColumnWrapper
                    gap={2}
                    p={2.5}
                >
                    <FlexRowWrapper bgcolor={'white'}>
                        <Button
                            variant='contained'
                            color='success'
                            fullWidth
                            onClick={(e) => onSubmit()}
                            disabled={!formState.isValid}
                        >
                            Сохранить
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={leaveWithConfirm}
                        >
                            Отмена
                        </Button>
                    </FlexRowWrapper>
                    {mode === 'edit' && (
                        <Button
                            fullWidth
                            sx={{
                                color: '#f46b6b',
                            }}
                            color='warning'
                            startIcon={<IoMdTrash />}
                            onClick={deleteTemplate}
                        >
                            Удалить шаблон
                        </Button>
                    )}
                </FlexColumnWrapper>
            </StyledDrawer>

            {/* Модалка для подтверждения отмены настройки шаблона */}
            <ConfirmDialog
                open={isConfirmCancelDialogOpen}
                title={'Вы уверены, что хотите отменить настройку шаблона?'}
                message='Все несохраненные данные будут удалены'
                onYes={{
                    action: cancel,
                    btnText: 'Отменить',
                }}
                onNo={{
                    action: (e) => setIsConfirmCancelDialogOpen(() => false),
                }}
                DialogProps={{ sx: { zIndex: 9999999 } }}
            />

            {/* Модалка для подтверждения удаления шаблона */}
            <ConfirmDialog
                open={isConfirmDeleteDialogOpen}
                title={'Вы уверены, что хотите удалить шаблон'}
                onYes={{
                    action: deleteTemplate,
                    btnText: 'Удалить',
                }}
                onNo={{
                    action: (e) => setIsConfirmDeleteDialogOpen(() => false),
                }}
                DialogProps={{ sx: { zIndex: 9999999 } }}
            />
        </Box>
    );
}
