import { ColDef, ColGroupDef } from 'ag-grid-community'
import { CustomCellEditor, NumberCell, PlaceholderCellRenderer } from './GanttLinkDialog.components'
import { LINK_TYPES } from './GanttLinkDialog.config'
import { IGanttLinkDialogColDefProps } from './GanttLinkDialog.def'

export const GanttLinkDialogColDefs = ({
    rowData,
    targets,
    setTargets,
    selectedTaskId,
    independentTasks,
    getTasksAsAutocompleteOptions,
    getTasksAsSearchAutocompleteOptions,
    onHeaderCheckboxChange,
}: IGanttLinkDialogColDefProps): ColDef[] | ColGroupDef[] =>
    rowData
        ? [
              {
                  headerComponent: NumberCell,
                  editable: false,
                  field: 'number',
                  maxWidth: 80,
                  cellRenderer: NumberCell,
                  cellRendererParams: {
                      targets,
                      setTargets,
                      selectedTaskId,
                  },
                  headerComponentParams: {
                      onHeaderCheckboxChange,
                      targets,
                      setTargets,
                  },
              },
              {
                  headerName: 'Наименование задачи',
                  field: 'name',
                  flex: 1,
                  editable: true,
                  headerClass: 'ag-cell-left',
                  cellRenderer: PlaceholderCellRenderer,
                  cellRendererParams: {
                      placeholder: 'Выберите задачу',
                  },
                  cellEditor: CustomCellEditor,
                  cellEditorParams: {
                      dataType: 'autocomplete',
                      withNewOptionCreation: true,
                      options: independentTasks,
                      getOptions: getTasksAsAutocompleteOptions,
                      searchOptions: getTasksAsSearchAutocompleteOptions
                  },
                  cellClass: 'ag-cell-left ag-cell-editable',
              },
              {
                  headerName: 'Тип связи',
                  field: 'linkType',
                  maxWidth: 186,
                  editable: true,
                  cellRenderer: PlaceholderCellRenderer,
                  cellRendererParams: {
                      placeholder: 'Выберите связь',
                  },
                  cellEditor: CustomCellEditor,
                  cellEditorParams: {
                      dataType: 'autocomplete',
                      withNewOptionCreation: true,
                      options: LINK_TYPES.map((link) => ({ label: link.label, value: link.label })),
                  },
                  cellClass: 'ag-cell-left ag-cell-editable',
              },
              {
                  headerName: 'Запаздывание',
                  field: 'lag',
                  maxWidth: 186,
                  editable: true,
                  cellRenderer: PlaceholderCellRenderer,
                  cellRendererParams: {
                      placeholder: '0д',
                  },
                  cellEditor: CustomCellEditor,
                  cellEditorParams: {
                      dataType: 'number',
                  },
                  cellClass: 'ag-cell-left ag-cell-editable',
              },
          ]
        : []
