import { Button, Dialog, Stack, Typography } from '@mui/material'
import styled from 'styled-components'

export const DialogWindow = styled(Dialog)`
    .MuiDialog-container {
        .MuiPaper-root {
            width: 100%;
            max-width: 500px;
            border: 1px solid #fff;
            border-radius: 10px;
            padding: 20px;
            min-height: 200px;
            margin: 0;
        }
    }
`

export const Container = styled(Stack).attrs({
    direction: 'column',
    width: '100%',
    gap: 2,
})``

export const Text = styled(Typography)`
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #2b3648;
`

export const BoldText = styled('span')`
    font-weight: 500;
`

export const Buttons = styled(Stack).attrs({
    direction: 'column',
    width: '100%',
    gap: 1,
})``

export const BaseButton = styled(Button).attrs({
    variant: 'outlined',
})`
    font-weight: 500;
    min-height: 40px;
    font-size: 12px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
`

export const CancelButton = styled(BaseButton).attrs({})`
    color: #f46b6b;
    border-color: #f46b6b;
    transition: border-color 0.2s ease-in-out;

    &:hover {
        border-color: #d32f2f;
    }
`
