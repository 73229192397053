import { format } from 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';

import { useGetProjectDates } from '../DHTGantOnlyTable.model';
import { GanttStatic, ZoomLevels } from '../dhtmlxgantt';

type TSetGanttConfigOptions = {
    projectDates: ReturnType<typeof useGetProjectDates>;
    highlightCriticalPath?: boolean;
    rowHeight: number;
    taskClass: string;
    linkClass: string;
};

export function setGanttConfig(
    gantt: GanttStatic,
    { projectDates, highlightCriticalPath = false, rowHeight, taskClass, linkClass }: TSetGanttConfigOptions
) {
    gantt.plugins({
        undo: true,
        redo: true,
        tooltip: true,
        auto_scheduling: false,
        critical_path: true,
        split_task: true,
    });

    gantt.templates.tooltip_text = function (start, end, task) {
        // const taskId = Number.isNaN(Number(task.id)) ? task.parent : task.id;
        return (
            // '<b>ID:</b> ' +
            // taskId +
            // '<br/>' +
            '<b>Задача:</b> ' +
            task.text +
            '<br/>' +
            '<b>Дата начала:</b> ' +
            (task.start_date ? format(task.start_date, 'dd.MM.yyyy') : 'Не установлена') +
            '<br/><b>Дата окончания:</b> ' +
            (task.end_date ? format(task.end_date, 'dd.MM.yyyy') : 'Не установлена')
        );
    };

    gantt.config.drag_progress = false;
    gantt.config.scale_height = 41;
    gantt.config.undo_types = {
        link: 'link',
        task: 'task',
    };
    gantt.config.scales = [
        { unit: 'month', format: '%F, %Y' },
        {
            unit: 'day',
            step: 1,
        },
    ];
    gantt.config.open_tree_initially = true;
    gantt.config.duration_unit = 'day';
    // gantt.config.bar_height = 10;
    gantt.config.auto_scheduling = true;
    gantt.config.auto_scheduling_strict = true;
    gantt.config.auto_scheduling_initial = true;
    gantt.config.auto_scheduling_project_constraint = true;
    gantt.config.undo = true;
    gantt.config.show_grid = false;
    gantt.config.row_height = rowHeight;

    gantt.config.min_duration = 24 * 60 * 60 * 1000;
    gantt.config.round_dnd_dates = false;
    gantt.config.time_step = 24 * 60;
    gantt.config.highlight_critical_path = highlightCriticalPath;

    let zoomConfig = {
        columnWidth: 80,
        levels: [
            [
                {
                    unit: 'year',
                    format: '%Y',
                    step: 1,
                    css: () => 'gantt_custom_scale_cell_class',
                },
            ],
            [
                {
                    unit: 'year',
                    format: '%Y',
                    step: 1,
                    css: () => 'gantt_custom_scale_cell_class',
                },
                {
                    unit: 'month',
                    format: '%M',
                    step: 1,
                    css: () => 'gantt_custom_scale_cell_class--small',
                },
            ],
            [
                {
                    unit: 'month',
                    format: '%M %Y',
                    step: 1,
                    css: () => 'gantt_custom_scale_cell_class',
                },
                {
                    unit: 'day',
                    format: '%d %M',
                    step: 1,
                    css: () => 'gantt_custom_scale_cell_class--small',
                },
            ],
        ] as unknown as ZoomLevels[],
        startDate: projectDates?.start,
        endDate: projectDates?.end,
        useKey: 'ctrlKey',
        trigger: 'wheel',
        activeLevelIndex: 1,
        element: function () {
            return gantt.$root.querySelector('.gantt_task');
        },
    };

    gantt.ext.zoom.init(zoomConfig);

    gantt.templates.scale_row_class = function () {
        return 'gantt_custom_scale_row_class';
    };

    gantt.templates.scale_cell_class = function (date) {
        return 'gantt_custom_scale_cell_class';
    };

    gantt.templates.task_class = function (_start, _end, task) {
        if (task.isProjectCollapsed) {
            return 'collapsed_project';
        }

        if (task.level === 0) {
            return 'level_zero_bar parent_bar';
        }

        if (task.hasChildren) {
            return 'parent_bar';
        }
        if (!task.hasChildren) {
            if (task.split) {
                return 'no_drag_handles child_bar';
            } else {
                return `child_bar ${taskClass}`;
            }
        }
        return 'asd';
    };

    gantt.templates.link_class = function (_link) {
        return '' + linkClass;
    };

    gantt.config.external_render = {
        // checks the element is a React element
        isElement: (element: {} | null | undefined) => {
            return React.isValidElement(element);
        },
        // renders the React element into the DOM
        renderElement: (
            element: React.DOMElement<React.DOMAttributes<Element>, Element>,
            container: ReactDOM.Container | null
        ) => {
            ReactDOM.render(element, container);
        },
    };

    gantt.i18n.setLocale('ru');
}
