import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { i18n } from './i18n';
import { Layout } from './layouts/Layout';
import KsgLayout from './layouts/TableLayout/KsgLayout';
import MsgLayout from './layouts/TableLayout/MsgLayout';
import AgGrid from './pages/AgGrid/AgGrid';
import { AgGridKsgKC6A } from './pages/AgGridKsgKC6A/AgGridKsgKC6A';
import AgGridKsgMim from './pages/AgGridKsgMim/AgGridKsgMim';
import AgGridKsgPeople from './pages/AgGridKsgPeople/AgGridKsgPeople';
import AgGridMsg from './pages/AgGridMsg/AgGridMsg';
import AgGridMsgEquip from './pages/AgGridMsgEquip/AgGridMsgEquip';
import AgGridMsgPeople from './pages/AgGridMsgPeople/AgGridMsgPeople';
import { BrigadesPage } from './pages/BrigadesPage/BrigadesPage';
import { AddBrigadePage } from './pages/BrigadesPage/subPages/AddBrigadePage/AddBrigadePage';
import DHTMLXGanttWithAgGrid from './pages/DHTGant/DHTGantWithAgGrid';
import { MSGPrint } from './pages/MSGPrint/MSGPrint';
import { MSGResourcePage } from './pages/NewExecutorView/MSGResourcePage/MSGResourcePage';
import { WorkResourcesListPage } from './pages/NewExecutorView/WorkResourcesListPage/WorkResourcesListPage';
import { WorksSubPage } from './pages/NewExecutorView/WorksSubPage/WorksSubPage';
import NewExecutorLayout from './pages/NewExecutorView/layouts/NewExecutorViewLayout';
import Projects from './pages/Projects/Projects';
import SocketTest from './pages/SocketTest/SocketTest';
import SufficiencyOfMaterials from './pages/SufficiencyOfMaterials/SufficiencyOfMaterials';
import AddUser from './pages/Users/AddUser/AddUser';
import EditUser from './pages/Users/EditUser/EditUser';
import Users from './pages/Users/Users';
import { WorkManagmentLayout } from './pages/WorkManagment/layouts/WorkManagmentLayout';
import { EstimatePositionsPage } from './pages/WorkManagment/subPages/Estimate/subPages/Positions/EstimatePositionsPage';
import { EstimateResourcesPage } from './pages/WorkManagment/subPages/Estimate/subPages/Resources/EstimateResourcesPage';
import { MobilizationSchedulePage } from './pages/WorkManagment/subPages/MobilizationSchedule/MobilizationSchedulePage';
import { ResourcesPage } from './pages/WorkManagment/subPages/Resources/ResourcesPage';
import { resetFilterTitlesArr } from './store/slices/filtersWorkersMimSlice';
import { setMonthTerm } from './store/slices/monthMsgSlice';
import { refreshTemplateMsg } from './store/slices/msgTemplatesSlice';
import { setPage } from './store/slices/paginationSlice';
import { setPageName } from './store/slices/projectsSlice';
import { store } from './store/store';
import './styles/styles.scss';
import { theme } from './styles/theme';

export enum pagesNames {
    projects = 'projects',
    projects_charts = 'projects_charts',
    users = 'users',
    users_add = 'users_add',
    users_edit = 'users_edit',
    ksg = 'ksg',
    ksg_people = 'ksg_people',
    ksg_equip = 'ksg_equip',
    ksg_kc6a = 'ksg_kc6a',
    msg = 'msg',
    msg_people = 'msg_people',
    msg_equip = 'msg_equip',
    executor = 'executor',
    executor_details_mim = 'executor_details_mim',
    executor_details_staff = 'executor_details_staff',
    executor_people = 'executor_people',
    executor_equip = 'executor_equip',
    ag_grid = 'ag_grid',
    ag_grid_msg = 'ag_grid_msg',
    ag_grid_ksg_people = 'ag_grid_ksg_people',
    ag_grid_ksg_equip = 'ag_grid_ksg_equip',
    ag_grid_temp = 'ag_grid_temp',
    mto = 'mto',
    workManagement = 'workManagement',
    brigades = 'brigades',
    addBrigade = 'addBrigade',
    editBrigade = 'editBrigade',
    msg_print = 'msg_print',
    secretGantt = 'secretGantt',
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            { path: 'test/:id', element: <SocketTest /> },
            {
                path: '/',
                children: [
                    {
                        index: true,
                        loader: () => {
                            store.dispatch(setPageName(pagesNames.projects));
                            return null;
                        },
                        element: <Projects />,
                    },
                    // { path: 'core-auth', element: <AuthPage /> },
                    {
                        path: 'brigades/:projectId',
                        loader: () => {
                            store.dispatch(setPageName(pagesNames.brigades));
                            return null;
                        },
                        element: <BrigadesPage />,
                    },
                    {
                        path: 'secretPathGanttDHTMLX/:projectId',
                        loader: () => {
                            store.dispatch(setPageName(pagesNames.secretGantt));
                            return null;
                        },
                        element: <AgGrid />,
                    },
                    {
                        path: 'brigades/:projectId/add',
                        loader: () => {
                            store.dispatch(setPageName(pagesNames.addBrigade));
                            return null;
                        },
                        element: <AddBrigadePage />,
                        index: true,
                    },
                    {
                        path: 'brigades/:projectId/edit/:brigadeID',
                        loader: () => {
                            store.dispatch(setPageName(pagesNames.editBrigade));
                            return null;
                        },
                        element: <AddBrigadePage />,
                        index: true,
                    },
                    {
                        path: 'charts',
                        loader: () => {
                            store.dispatch(setPageName(pagesNames.projects_charts));
                            return null;
                        },
                        element: <Projects charts />,
                    },
                    {
                        path: 'ksg',
                        element: <KsgLayout />,
                        children: [
                            {
                                path: ':projectId',
                                children: [
                                    {
                                        index: true,
                                        loader: () => {
                                            store.dispatch(setPageName(pagesNames.ksg));
                                            store.dispatch(
                                                setMonthTerm({
                                                    month: new Date().getMonth(),
                                                    year: new Date().getFullYear(),
                                                })
                                            );
                                            store.dispatch(setPage(1));
                                            return null;
                                        },
                                        element: <DHTMLXGanttWithAgGrid />,
                                    },
                                ],
                            },

                            {
                                path: 'workers',
                                children: [
                                    {
                                        path: ':projectId',
                                        children: [
                                            {
                                                index: true,
                                                loader: () => {
                                                    store.dispatch(setPageName(pagesNames.ksg_people));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridKsgPeople />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'ag_grid',
                                        children: [
                                            {
                                                path: ':projectId',
                                                loader: () => {
                                                    store.dispatch(setPageName(pagesNames.ksg_people));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridKsgPeople />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'mim',
                                children: [
                                    {
                                        path: ':projectId',
                                        children: [
                                            {
                                                index: true,
                                                loader: () => {
                                                    store.dispatch(setPageName(pagesNames.ksg_equip));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridKsgMim />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'ag_grid',
                                        children: [
                                            {
                                                path: ':projectId',
                                                loader: () => {
                                                    store.dispatch(setPageName(pagesNames.ksg_equip));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridKsgMim />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'ag_grid',
                                children: [
                                    {
                                        path: ':projectId',
                                        children: [
                                            {
                                                index: true,
                                                loader: () => {
                                                    store.dispatch(setPageName(pagesNames.ag_grid));
                                                    return null;
                                                },
                                                element: <AgGrid />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'kc6a',
                                children: [
                                    {
                                        path: ':projectId',
                                        loader: () => {
                                            store.dispatch(setPageName(pagesNames.ksg_kc6a));
                                            store.dispatch(resetFilterTitlesArr());
                                            return null;
                                        },
                                        element: <AgGridKsgKC6A />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'ksg/:projectId/mto/:workId',
                        children: [
                            {
                                index: true,
                                loader: () => {
                                    store.dispatch(setPageName(pagesNames.mto));
                                    return null;
                                },
                                element: <SufficiencyOfMaterials />,
                            },
                        ],
                    },
                    {
                        path: 'msg',
                        element: <MsgLayout />,
                        children: [
                            {
                                path: ':projectId',
                                children: [
                                    {
                                        index: true,
                                        loader: () => {
                                            store.dispatch(setPageName(pagesNames.msg));
                                            store.dispatch(refreshTemplateMsg());
                                            return null;
                                        },
                                        element: <AgGridMsg />,
                                    },
                                ],
                            },
                            {
                                path: 'workers',
                                children: [
                                    {
                                        path: ':projectId',
                                        children: [
                                            {
                                                index: true,
                                                loader: () => {
                                                    store.dispatch(setPageName(pagesNames.msg_people));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridMsgPeople />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'ag_grid',
                                        children: [
                                            {
                                                path: ':projectId',
                                                loader: () => {
                                                    store.dispatch(setPageName(pagesNames.msg_people));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridMsgPeople />,
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                path: 'mim',
                                children: [
                                    {
                                        path: ':projectId',
                                        children: [
                                            {
                                                index: true,
                                                loader: () => {
                                                    store.dispatch(setPageName(pagesNames.msg_equip));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridMsgEquip />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'ag_grid',
                                        children: [
                                            {
                                                path: ':projectId',
                                                loader: () => {
                                                    store.dispatch(setPageName(pagesNames.msg_equip));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridMsgEquip />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'ag_grid',
                                children: [
                                    {
                                        path: ':projectId',
                                        children: [
                                            {
                                                index: true,
                                                loader: () => {
                                                    store.dispatch(setPageName(pagesNames.ag_grid_msg));
                                                    return null;
                                                },
                                                element: <AgGridMsg />,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'msg/:projectId/print',
                        loader: () => {
                            store.dispatch(setPageName(pagesNames.msg_print));
                            return null;
                        },
                        element: <MSGPrint />,
                    },
                    {
                        path: 'workManagment',

                        children: [
                            {
                                path: ':projectId/:workID',
                                element: <WorkManagmentLayout />,
                                loader: () => {
                                    store.dispatch(setPageName(pagesNames.workManagement));
                                    // store.dispatch(setPage(1))
                                    return null;
                                },
                                children: [
                                    {
                                        path: 'estimate/resources',
                                        element: <EstimateResourcesPage />,
                                    },
                                    {
                                        path: 'estimate/positions',
                                        element: <EstimatePositionsPage />,
                                    },
                                    {
                                        path: 'resources',
                                        element: <ResourcesPage />,
                                    },
                                    {
                                        path: 'mobilization',
                                        element: <MobilizationSchedulePage />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: '*',
                        loader: () => {
                            store.dispatch(setPageName(pagesNames.projects));
                            return null;
                        },
                        element: <Projects />,
                    },
                ],
            },
            {
                path: 'users',
                children: [
                    {
                        index: true,
                        loader: () => {
                            store.dispatch(setPageName(pagesNames.users));
                            return null;
                        },
                        element: <Users />,
                    },
                    {
                        path: 'add',
                        loader: () => {
                            store.dispatch(setPageName(pagesNames.users_add));
                            return null;
                        },
                        element: <AddUser />,
                    },
                    {
                        path: 'edit/:userId',
                        loader: () => {
                            store.dispatch(setPageName(pagesNames.users_edit));
                            return null;
                        },
                        element: <EditUser />,
                    },
                    {
                        path: '*',
                        loader: () => {
                            store.dispatch(setPageName(pagesNames.users));
                            return null;
                        },
                        element: <Users />,
                    },
                ],
            },
            {
                path: '*',
                loader: () => {
                    store.dispatch(setPageName(pagesNames.projects));
                    return null;
                },
                element: <Projects />,
            },
        ],
    },
    {
        path: 'executor',
        element: <NewExecutorLayout />,
        children: [
            {
                path: 'works',
                index: true,
                loader: () => {
                    store.dispatch(setPageName(pagesNames.executor));
                    return null;
                },
                element: <WorksSubPage />,
            },
            {
                index: true,
                path: 'resources/:projectID/:workID*',
                element: <WorkResourcesListPage />,
            },
            // {
            //     path: 'details/mim/:detailsId',
            //     loader: () => {
            //         store.dispatch(setPageName(pagesNames.executor_details_mim))
            //         return null
            //     },
            //     element: <WorkDetailsMim />,
            // },
            // {
            //     path: 'details/staff/:detailsId',
            //     loader: () => {
            //         store.dispatch(setPageName(pagesNames.executor_details_staff))
            //         return null
            //     },
            //     element: <WorkDetailsStaff />,
            // },
            {
                path: 'staffs',
                loader: () => {
                    store.dispatch(setPageName(pagesNames.executor_people));
                    return null;
                },
                element: (
                    <MSGResourcePage
                        key='staffs'
                        resourceType='staffs'
                    />
                ),
            },
            {
                path: 'mims',
                loader: () => {
                    store.dispatch(setPageName(pagesNames.executor_equip));
                    return null;
                },
                element: (
                    <MSGResourcePage
                        key='mims'
                        resourceType='technics'
                    />
                ),
            },
            // {
            //     path: "*",
            //     element: <Navigate to="/executor/works?listType=waitList" />
            // }
        ],
    },
    // {
    //     path: "*",
    //     element: <Box>404</Box>
    // }
]);

const snackbarAnchorOrigin: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <StyledThemeProvider theme={theme}>
                <I18nextProvider i18n={i18n}>
                    <Provider store={store}>
                        <SnackbarProvider
                            anchorOrigin={snackbarAnchorOrigin}
                            maxSnack={3}
                            autoHideDuration={2500}
                            // action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
                            style={{
                                maxWidth: 500,
                            }}
                        >
                            <div className='app'>
                                <RouterProvider router={router} />
                            </div>
                        </SnackbarProvider>
                    </Provider>
                </I18nextProvider>
            </StyledThemeProvider>
        </ThemeProvider>
    </StyledEngineProvider>
    // </React.StrictMode>
);
