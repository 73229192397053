import { ILibGanttLink, TLocalGanttLinkId } from '../DHTGant.def';
import { GanttStatic } from '../dhtmlxgantt';

type HandlersType = {
    onTaskDblClick: (taskId: number) => void;
    onBeforeLinkAdd: (id: TLocalGanttLinkId, link: ILibGanttLink) => void;
    onBeforeLinkDelete: (id: TLocalGanttLinkId, link: ILibGanttLink) => void;
    onBeforeTaskDrag: (id: number, mode: string, e: MouseEvent) => boolean;
    onAfterTaskDrag: (taskId: number) => void;
    onTaskClick: (taskId: number, e: MouseEvent) => void;
    onTaskLoaded: () => void;
    onGridResizeStart: () => void;
    onGridResize: () => void;
    onGridResizeEnd: () => void;
};
export function getGanttEventHandlers(gantt: GanttStatic, handlers: HandlersType) {
    if (!gantt) return [];

    const events = [];

    events.push(
        gantt.attachEvent('onGanttLayoutReady', function () {
            // Отображаем всегда горизонтальный скролл, чтобы было равенство с гридом в концу таблицы
            gantt.$ui.getView('main').constructor.prototype._resizeScrollbars = function () {};
        })
    );

    events.push(
        gantt.attachEvent('onGanttReady', function () {
            let tooltips = gantt.ext.tooltips;
            tooltips.tooltip.setViewport(gantt.$task_data);
        })
    );
    events.push(gantt.attachEvent('onBeforeLinkAdd', handlers.onBeforeLinkAdd));
    events.push(gantt.attachEvent('onBeforeLinkDelete', handlers.onBeforeLinkDelete));

    events.push(gantt.attachEvent('onBeforeTaskDrag', handlers.onBeforeTaskDrag));
    events.push(
        gantt.attachEvent('onAfterTaskDrag', (id, mode) => {
            if (!['move', 'resize'].includes(mode)) return;
            handlers.onAfterTaskDrag(Number(id));
        })
    );

    events.push(gantt.attachEvent('onGridResizeStart', handlers.onGridResizeStart));
    events.push(gantt.attachEvent('onGridResize', handlers.onGridResize));
    events.push(gantt.attachEvent('onGridResizeEnd', handlers.onGridResizeEnd));

    events.push(gantt.attachEvent('onTaskDblClick', handlers.onTaskDblClick));
    events.push(gantt.attachEvent('onTaskClick', handlers.onTaskClick));

    events.push(gantt.attachEvent('onParse', handlers.onTaskLoaded));

    return events;
}
