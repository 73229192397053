import React, { MouseEvent } from 'react'
import { AiOutlineHome } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '@mui/material'

export default function IconButtonEl() {
    const navigate = useNavigate()

    return (
        <IconButton
            color="primary"
            component="button"
            sx={{
                margin: '0 0 0.2rem 0.1rem',
                paddingLeft: 0 
            }}
            disableRipple
            disableTouchRipple
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                navigate('/')
            }}
        >
            <AiOutlineHome color="#0044b4" fontSize="1.5rem" />
        </IconButton>
    )
}
