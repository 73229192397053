import { Button, FormControl, MenuItem, Select, TextField } from '@mui/material'
import { useFormik } from 'formik'
import s from './ProjectsDrawer.module.scss'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ru } from 'date-fns/locale'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { CgDanger } from 'react-icons/cg'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import { Fragment, useState } from 'react'
import { timezones, validationSchema } from '../Projects.service'
import ConfirmationModal from '../../../shared/components/ConfirmationModal/ConfirmationModal'

export const inputStylesAddProject = {
    width: '23rem',
    backgroundColor: '#F6F7FB',
    '.MuiInputBase-root': {
        border: '1px solid rgba(92, 110, 140, 0.1)',
        borderRadius: '8px',
        fontSize: 14,
    },
}

export const calendarStylesAddProject = {
    backgroundColor: '#F6F7FB',
    border: '1px solid rgba(92, 110, 140, 0.1)',
    borderRadius: '8px',
    '.MuiInputBase-input': {
        padding: '0.5rem',
        maxWidth: '6rem',
        fontSize: 14,
    },
}

export type formObjType = {
    title: string | undefined
    abbr: string | undefined
    description: string | undefined
    status: string | undefined
    timezone: number | undefined
    dateStart: Date | null
    dateEnd: Date | null
}

interface FormErrorI {
    isTouched?: boolean
    error?: string
    right?: boolean
}

export function FormError({ isTouched, error, right }: FormErrorI) {
    return (
        <div
            style={{
                textAlign: right ? 'right' : 'unset',
            }}
            className={s.error}
        >
            {error && isTouched && <div className="error">{error}</div>}
        </div>
    )
}

export const initialFormValues = {
    title: '',
    abbr: '',
    description: '',
    status: 'Активный',
    timezone: 3,
    dateStart: null,
    dateEnd: null,
}

export default function FormikFormC({
    onSubmit,
    serverValues,
    onClose,
}: {
    onSubmit: (values: formObjType) => void
    serverValues?: formObjType
    onClose: () => void
}) {
    const [warningModal, setWarningModal] = useState(false)

    const formik = useFormik({
        initialValues: serverValues ? serverValues : initialFormValues,
        onSubmit,
        validationSchema,
    })

    const isDisabled = () => {
        return !formik.isValid && Object.values(formik.touched).every((item) => item)
    }

    return (
        <Fragment>
            <ConfirmationModal
                openState={warningModal}
                onCancel={() => setWarningModal(false)}
                onConfirm={onClose}
                title="Подтвердить закрытие формы?"
                content="Форма будет закрыта, а все введенные данные безвозвратно утеряны."
            />

            <form className={s.form} onSubmit={formik.handleSubmit}>
                <section className={s.inputs}>
                    <div className={s.field_container}>
                        <TextField
                            data-testid={'title'}
                            InputLabelProps={{ shrink: false }}
                            placeholder="Краткое наименование проекта"
                            sx={inputStylesAddProject}
                            error={!!formik.errors.title?.length}
                            {...formik.getFieldProps('title')}
                        />
                        <FormError isTouched={formik.touched.title} error={formik.errors.title} />
                    </div>
                    <div className={s.field_container}>
                        <TextField
                            data-testid={'abbr'}
                            InputLabelProps={{ shrink: false }}
                            placeholder="Аббревиатура"
                            sx={inputStylesAddProject}
                            error={!!formik.errors.abbr?.length}
                            {...formik.getFieldProps('abbr')}
                        />
                        <FormError isTouched={formik.touched.abbr} error={formik.errors.abbr} />
                    </div>
                    <div className={s.field_container}>
                        <TextField
                            data-testid={'description'}
                            multiline
                            InputLabelProps={{ shrink: false }}
                            placeholder="Полное название проекта или его описание"
                            rows={2}
                            sx={inputStylesAddProject}
                            error={!!formik.errors.description?.length}
                            {...formik.getFieldProps('description')}
                        />
                        <FormError isTouched={formik.touched.description} error={formik.errors.description} />
                    </div>
                </section>

                <section className={s.field_container}>
                    <div className={s.select}>
                        <div className={s.select_left}>
                            <CgDanger color="#5c6e8c" fontSize={20} />
                            <p>Статус:</p>
                        </div>
                        <FormControl
                            sx={{
                                minWidth: '11rem',
                            }}
                        >
                            <Select
                                data-testid={'status'}
                                sx={{
                                    backgroundColor: '#F6F7FB',

                                    '.MuiSelect-select': {
                                        padding: '0.5rem',
                                        color: '#0044B4',
                                    },
                                }}
                                {...formik.getFieldProps('status')}
                                error={!!formik.errors.status?.length}
                            >
                                {['Приостановлен', 'Активный', 'Завершенный'].map((v) => (
                                    <MenuItem key={v} value={v}>
                                        {v} проект
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <FormError isTouched={formik.touched.status} error={formik.errors.status} right />
                </section>

                <section className={s.field_container}>
                    <div className={s.select}>
                        <div className={s.select_left}>
                            <LocationOnIcon
                                sx={{
                                    color: '#5c6e8c',
                                    width: '1.25rem',
                                    height: '1.25rem',
                                }}
                            />
                            <p>Регион РФ:</p>
                        </div>
                        <FormControl
                            sx={{
                                minWidth: '13rem',
                            }}
                        >
                            <Select
                                data-testid={'timezone'}
                                sx={{
                                    backgroundColor: '#F6F7FB',

                                    '.MuiSelect-select': {
                                        padding: '0.5rem',
                                        color: '#0044B4',
                                    },
                                }}
                                {...formik.getFieldProps('timezone')}
                                error={!!formik.errors.timezone?.length}
                            >
                                {timezones.map((v) => (
                                    <MenuItem key={v.title} value={v.offset}>
                                        {v.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <FormError isTouched={formik.touched.timezone} error={formik.errors.timezone} right />
                </section>

                <section className={s.dates}>
                    <p>Сроки реализации:</p>
                    <div className={s.field_container}>
                        <div className={s.dates_line}>
                            <div>
                                <CalendarMonthOutlinedIcon
                                    sx={{
                                        color: '#5c6e8c',
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                />
                                <p>Дата начала:</p>
                            </div>

                            <LocalizationProvider adapterLocale={ru} dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    value={formik.values.dateStart}
                                    onChange={(newValue) => {
                                        formik.setFieldValue('dateStart', newValue)
                                    }}
                                    inputFormat="dd.MM.yyyy"
                                    renderInput={(params) => {
                                        if (params.inputProps) params.inputProps.placeholder = 'дд.мм.гггг'

                                        return (
                                            <TextField
                                                data-testid={'dateStart'}
                                                {...params}
                                                onBlur={() =>
                                                    formik.setTouched({
                                                        ...formik.touched,
                                                        dateStart: true,
                                                    })
                                                }
                                                error={!!formik.errors.dateStart?.length}
                                                sx={calendarStylesAddProject}
                                            />
                                        )
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <FormError isTouched={formik.touched.dateStart} error={formik.errors.dateStart} right />
                    </div>

                    <div className={s.field_container}>
                        <div className={s.dates_line}>
                            <div>
                                <EventAvailableIcon
                                    sx={{
                                        color: '#5c6e8c',
                                        width: '1.2rem',
                                        height: '1.2rem',
                                    }}
                                />
                                <p>Дата окончания:</p>
                            </div>
                            <LocalizationProvider adapterLocale={ru} dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    value={formik.values.dateEnd}
                                    onChange={(newValue) => {
                                        formik.setFieldValue('dateEnd', newValue)
                                    }}
                                    inputFormat="dd.MM.yyyy"
                                    disabled={!formik.values.dateStart}
                                    shouldDisableDate={(date) => {
                                        if (!formik.values.dateStart) return true
                                        return date.getTime() <= formik.values.dateStart.getTime()
                                    }}
                                    renderInput={(params) => {
                                        if (params.inputProps) {
                                            params.inputProps.placeholder = 'дд.мм.гггг'
                                        }
                                        return (
                                            <TextField
                                                data-testid={'dateEnd'}
                                                {...params}
                                                disabled={!formik.values.dateStart}
                                                error={!!formik.errors.dateEnd?.length}
                                                onBlur={() =>
                                                    formik.setTouched({
                                                        ...formik.touched,
                                                        dateEnd: true,
                                                    })
                                                }
                                                sx={calendarStylesAddProject}
                                            />
                                        )
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <FormError isTouched={formik.touched.dateEnd} error={formik.errors.dateEnd} right />
                    </div>
                </section>

                <section className={s.buttons}>
                    <Button
                        type="submit"
                        disabled={isDisabled()}
                        sx={{
                            backgroundColor: '#2E7D32',
                            width: '11rem',
                            color: '#fff',

                            '&:hover': {
                                backgroundColor: '#3a993e',
                            },
                        }}
                        variant="contained"
                        color="inherit"
                    >
                        {serverValues ? 'Изменить' : 'Добавить'}
                    </Button>
                    <Button
                        onClick={formik.dirty ? () => setWarningModal(true) : onClose}
                        sx={{ backgroundColor: '#0044B4', width: '11rem' }}
                        variant="contained"
                    >
                        Oтменить
                    </Button>
                </section>
            </form>
        </Fragment>
    )
}
