import { useTypedSelector } from '../../../store/store'
import React from 'react'
import g from '../../../shared/stylesheets/TableGrid.module.scss'
import { FaFilter } from 'react-icons/fa'
import { filtersWorkersMimSelector } from '../../../store/slices/filtersWorkersMimSlice'

export const HeaderFilterPeqEl = ({
    width,
    name,
    onFilterClick,
}: {
    width?: number
    name: string
    onFilterClick: () => void
}) => {
    const { titlesArr } = useTypedSelector(filtersWorkersMimSelector)

    return (
        <div
            style={{
                width: (width || 32) - 32,
            }}
        >
            <p>{name}</p>
            <span
                onClick={() => {
                    onFilterClick()
                }}
                className={g.abs_right_center + ' pointer'}
            >
                <FaFilter color={titlesArr.length ? '#6FCCBC' : '#fff'} />
            </span>
        </div>
    )
}
