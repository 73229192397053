import { GanttStatic } from 'dhtmlx-gantt'
import { req } from '../../../WorkManagment/api/api'
import { ILibGanttLink, ITask, TGanttLinkPayload, TLocalGanttLinkId } from '../../DHTGant.def'
import { LINK_TYPES } from './GanttLinkDialog.config'
import {
    batchUpdateTasksDates,
    deleteLinkPermanently,
    getGanttLinksBySourceAndTarget,
} from '../../DHTGantOnlyTable.utils'
import { AgGridReact } from 'ag-grid-react'
import { onEditAgKsg } from '../../../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { AppDispatch } from '../../../../store/store'

export const formatLinkType = (linkType: string, isLabel: boolean) => {
    const [result] = LINK_TYPES.filter((link) => (isLabel ? link.type === linkType : link.label === linkType))
    return isLabel ? result.label : result.type
}

export const returnformatLinkType = (linkType: string) => {
    const [link] = LINK_TYPES.filter((link) => link.label === linkType)
    return link.type
}

export const fetchIndependentTasks = async (projectId: string, selectedTaskId: TLocalGanttLinkId) => {
    const { data } = await req.get(
        `/projects/${projectId}/dependencies/works/ksg/bind/dropdown/works?workId=${selectedTaskId}`
    )
    return data.data
}

export const searchIndependentTasks = async (projectId: string, selectedTaskId: TLocalGanttLinkId, searchValue: string) => {
    const { data } = await req.get(
        `/projects/${projectId}/dependencies/works/ksg/bind/dropdown/search?workId=${selectedTaskId}&filter=${searchValue}`
    )
    return data.data
}

export const deleteMassLinkDialog = async (
    gantt: GanttStatic,
    targets: Set<TLocalGanttLinkId>,
    selectedTaskId: TLocalGanttLinkId,
    projectId: string,
    grid: AgGridReact<any>,
    dispatch: AppDispatch
) => {
    const links = gantt.getLinks() as ILibGanttLink[]
    const deletedLinks: ILibGanttLink[] = Array.from(targets).flatMap(
        (target) => getGanttLinksBySourceAndTarget(links, selectedTaskId as number, target as number)[0]
    )
    const payload = {
        data: deletedLinks.map(({ id: _, ...link }) => ({ ...link })),
    }
    const { data } = await req.post(`/projects/${projectId}/dependencies/works/ksg/unbind-mass`, payload)

    for (const link of deletedLinks) {
        deleteLinkPermanently(gantt, link.id)
    }
    if (data.updatedWorks.length > 0) {
        dispatch(onEditAgKsg(data.updatedWorks))
        // batchUpdateTasksDates(gantt)(data.updatedWorks)
        // grid.api.applyTransaction({ update: data.updatedWorks })
    }
}

export const addLinkDialog = async (
    gantt: GanttStatic,
    projectId: string,
    grid: AgGridReact<any>,
    payload: TGanttLinkPayload,
    dispatch: AppDispatch
) => {
    const { data } = await req.post(`/projects/${projectId}/dependencies/works/ksg/bind/v2`, payload)
    gantt.addLink({
        id: Date.now(),
        canAdd: true,
        ...payload,
    })
    if (data.updatedWorks.length > 0) {
        // batchUpdateTasksDates(gantt)(data.updatedWorks)
        // grid.api.applyTransaction({ update: data.updatedWorks })
        dispatch(onEditAgKsg(data.updatedWorks))
    }
}

export const deleteLinkDialog = async (
    gantt: GanttStatic,
    projectId: string,
    grid: AgGridReact<any>,
    deletePayload: TGanttLinkPayload,
    linkId: TLocalGanttLinkId,
    dispatch: AppDispatch
) => {
    const { data } = await req.post(`/projects/${projectId}/dependencies/works/ksg/unbind`, deletePayload)
    if (data) {
        deleteLinkPermanently(gantt, linkId)
        if (data.updatedWorks.length > 0) {
            // batchUpdateTasksDates(gantt)(data.updatedWorks)
            // grid.api.applyTransaction({ update: data.updatedWorks })
            dispatch(onEditAgKsg(data.updatedWorks))
        }
    }
}

export const editLinkDialog = async (
    gantt: GanttStatic,
    projectId: string,
    grid: AgGridReact<any>,
    payload: TGanttLinkPayload,
    deletePayload: TGanttLinkPayload,
    linkId: TLocalGanttLinkId,
    dispatch: AppDispatch
) => {
    const { data } = await req.post(`/projects/${projectId}/dependencies/works/ksg/replace`, {
        new: payload,
        old: deletePayload,
    })
    deleteLinkPermanently(gantt, linkId)
    gantt.addLink({
        id: Date.now(),
        canAdd: true,
        ...payload,
    })
    if (data.updatedWorks.length > 0) {
        dispatch(onEditAgKsg(data.updatedWorks))
    }
}

export const getDeletePayload = (gantt: GanttStatic, selectedTaskId: TLocalGanttLinkId, id: number) => {
    const links = gantt.getLinks() as ILibGanttLink[]
    const [link] = getGanttLinksBySourceAndTarget(links, selectedTaskId as number, id)

    const deletePayload: TGanttLinkPayload = {
        source: Number(link.source),
        target: Number(link.target),
        type: link.type,
        lag: link.lag ? link.lag : 0,
    }
    return { deletePayload, linkId: link.id }
}

export const findTaskType = (sourceId: number, targetId: number, tasks: ITask[]) => {
    const selectedTask = tasks.find((task) => task.id === sourceId)
    if (selectedTask) {
        const source = selectedTask.workBonds.worksAfter.find((id: number) => id === targetId)
        if (source) {
            return 'source'
        }
        const target = selectedTask.workBonds.worksBefore.find((id: number) => id === targetId)
        if (target) {
            return 'target'
        }
        return false
    }
    return false
}
