import { Button } from '@mui/material'
import React, { Fragment, useCallback, useState } from 'react'
import { IoMdTrash } from 'react-icons/io'
import { toggleChangeMsgTemplate } from '../../store/slices/drawersSlice'
import { fixShow } from '../../store/slices/cpgViewSlice'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { bodyTemplateMsg } from './TableTemplate.service'
import s from './Templates.module.scss'
import { useDeleteTemplateMsgMutation, useUpdateTemplateMsgMutation } from '../../api/templates/templates.api'
import SharedTemplateDrawer from './components/SharedTemplateDrawer'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ConfirmationModal from '../../shared/components/ConfirmationModal/ConfirmationModal'
import {
    applyTemplateMsg,
    checkTemplateMsgItemView,
    initMsgTemplateStateType,
    msgTemplatesSelector,
    refreshTemplateMsg,
} from '../../store/slices/msgTemplatesSlice'
import { sharedTemplatesSelector, updateTemplatesMsgNamesList } from '../../store/slices/templatesSharedSlice'
import { refreshTemplateCpg } from '../../store/slices/ksgTemplatesSlice'

export default function ChangeMsgTemplate() {
    const [warningModal, setWarningModal] = useState(false)

    const { templateName, templateId } = useTypedSelector(sharedTemplatesSelector)
    const { templateMsgStateView, templateMsgState } = useTypedSelector(msgTemplatesSelector)

    const dispatch = useAppDispatch()

    const { projectId } = useParams()

    const [changeTemplateReq] = useUpdateTemplateMsgMutation()
    const [deleteTemplateReq] = useDeleteTemplateMsgMutation()

    const handleChange = (checked: boolean, term: keyof Omit<initMsgTemplateStateType, 'name'>, type: fixShow) => {
        dispatch(checkTemplateMsgItemView(term, checked, type))
    }

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('mutations')

    function sendChangeTemplate() {
        dispatch(applyTemplateMsg())
        changeTemplateReq({
            id: Number(projectId),
            temId: templateId,
            body: {
                ...bodyTemplateMsg(templateMsgStateView),
                name: templateName,
            },
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('template_changed'), {
                    variant: 'success',
                })
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                })
                console.error(e)
            })
    }

    const deleteTemplate = useCallback(() => {
        return deleteTemplateReq({
            id: Number(projectId),
            temId: templateId,
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('template_deleted'), {
                    variant: 'success',
                })
                if (templateMsgState.name === templateName) {
                    dispatch(refreshTemplateMsg())
                    // dispatch(updateTemplatesMsgNamesList(templateName.toLowerCase()))
                }
                dispatch(toggleChangeMsgTemplate())
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                })
                console.error(e)
            })
    }, [projectId, templateId, templateMsgState.name, templateName])

    return (
        <Fragment>
            <div className={s.header}>
                <h4>Изменить шаблон таблицы МСГ</h4>
            </div>
            <div className={s.container_change}>
                <SharedTemplateDrawer
                    onSave={() => {
                        sendChangeTemplate()
                        dispatch(toggleChangeMsgTemplate())
                    }}
                    onCancel={() => {
                        dispatch(toggleChangeMsgTemplate())
                    }}
                    onCheckItemMsg={(checked, term, type) => {
                        handleChange(checked, term, type)
                    }}
                />

                <Button
                    fullWidth
                    sx={{
                        color: '#f46b6b',
                    }}
                    color="warning"
                    startIcon={<IoMdTrash />}
                    onClick={() => setWarningModal(true)}
                >
                    Удалить шаблон
                </Button>
            </div>

            <ConfirmationModal
                openState={warningModal}
                onCancel={() => setWarningModal(false)}
                onConfirm={deleteTemplate}
                title="Вы уверены что хотите удалить шаблон?"
                content={`После удаления шаблона ${templateName} эти данные будут безвозвратно утеряны.`}
            />
        </Fragment>
    )
}
