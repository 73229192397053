import { Stack } from '@mui/material';
import { CellValueChangedEvent } from 'ag-grid-community';
import { isAxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TGanttLinkType } from '@/api/relations/relations.def';

import { useOpenDeleteZeroValueFactDialog } from '@/components/DeleteZeroValueFactDialog';

import { FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';
import { IAutocompleteOption } from '@/pages/WorkManagment/components/CellEditors/CellEditor/CellEditor.def';

import { isShiftErrorsData } from '@/shared/guards/error.guards';
import { isTooManyRequests } from '@/shared/utils';
import { ShiftErrorCode } from '@/shared/utils/error.utils';

import { useAppDispatch } from '@/store/store';

import { ITask, TGanttLinkPayload, TLocalGanttLinkId } from '../../DHTGant.def';
import { GanttLinkDialogColDefs } from './GanttLinkDialog.colDef';
import { CustomNoRowsOverlay } from './GanttLinkDialog.components';
import { LINK_TYPES } from './GanttLinkDialog.config';
import { IGanttLinkDialogProps } from './GanttLinkDialog.def';
import { useGetRowData } from './GanttLinkDialog.model';
import {
    AddLinkButton,
    CloseButton,
    Container,
    DialogWindow,
    PlusIcon,
    RemoveButton,
    StyledAgGrid,
    Title,
    WorkName,
    WorkNameContainer,
} from './GanttLinkDialog.styles';
import {
    addLinkDialog,
    deleteMassLinkDialog,
    editLinkDialog,
    fetchIndependentTasks,
    findTaskType,
    formatLinkType,
    getDeletePayload,
    searchIndependentTasks,
} from './GanttLinkDialog.utils';

export const GanttLinkDialog: React.FC<IGanttLinkDialogProps> = ({
    gantt,
    grid,
    tasks,
    selectedTaskId,
    taskName,
    onClose,
    setOverlayTitle,
    setGanttLinkByFactRestrictionDialogData,
}) => {
    const { projectId } = useParams();
    const [targets, setTargets] = useState<Set<TLocalGanttLinkId>>(new Set());
    const [independentTasks, setIndependentTasks] = useState<IAutocompleteOption[]>([]);
    const [type, setType] = useState<TGanttLinkType>(LINK_TYPES[0].type);
    const gridRef = useRef<any>(null);
    const isValueChangedRef = useRef<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const { rowData, setRowData, refreshToggle, setRefreshToggle } = useGetRowData(
        tasks,
        projectId as string,
        selectedTaskId as TLocalGanttLinkId
    );
    const openDeleteZeroValueFactDialog = useOpenDeleteZeroValueFactDialog();

    const handleLinkDelete = async () => {
        if (!selectedTaskId || !projectId) return;
        setOverlayTitle(() => 'Удаляем связи');
        try {
            await deleteMassLinkDialog(gantt, targets, selectedTaskId, projectId, grid, dispatch);
            setRefreshToggle(!refreshToggle);
        } catch (error) {
            console.log('error', error);
        } finally {
            setOverlayTitle(() => '');
        }
    };

    const handleAddEmptyRow = () => {
        const newRow = { id: '', number: null, name: '', linkType: '', lag: 0 };
        setRowData([...rowData, newRow]);
    };

    const resetState = () => {
        setTargets((prevTargets) => {
            const newTargets = new Set(prevTargets);
            newTargets.clear();
            return newTargets;
        });
        setType(LINK_TYPES[0].type);
        onClose();
    };

    const getTasksAsAutocompleteOptions = useCallback(async (): Promise<IAutocompleteOption[]> => {
        const tasks = await fetchIndependentTasks(projectId as string, selectedTaskId as TLocalGanttLinkId);
        return tasks
            .filter((task: any) => task.id !== selectedTaskId)
            .map((task: any) => ({
                id: task.id,
                label: task.numOrder + ' ' + task.workName,
                value: task.workName,
            }));
    }, [projectId, selectedTaskId]);

    const getTasksAsSearchAutocompleteOptions = useCallback(
        async (searchValue: string): Promise<IAutocompleteOption[]> => {
            const tasks = await searchIndependentTasks(
                projectId as string,
                selectedTaskId as TLocalGanttLinkId,
                searchValue
            );
            return tasks
                .filter((task: any) => task.id !== selectedTaskId)
                .map((task: any) => ({
                    id: task.id,
                    label: task.numOrder + ' ' + task.workName,
                    value: task.workName,
                }));
        },
        [projectId, selectedTaskId]
    );

    const onRowEditingStopped = async (event: CellValueChangedEvent) => {
        if (isValueChangedRef.current) {
            if (!selectedTaskId || !projectId || targets.size) return;
            const type = typeof event.data.linkType == 'object' ? event.data.linkType.value : event.data.linkType;
            const targetName = typeof event.data.name == 'object' ? event.data.name.value : event.data.name;

            if (type && targetName) {
                const formatSelectedTaskId = Number(selectedTaskId);
                const formatEventTaskId = Number(event.data.name.id || event.data.id);

                if (event.data.id) {
                    setOverlayTitle(() => 'Редактируем связь');
                    const selectedTaskType = findTaskType(formatSelectedTaskId, formatEventTaskId, tasks);
                    const source = selectedTaskType === 'source' ? formatSelectedTaskId : formatEventTaskId;
                    const target = selectedTaskType === 'target' ? formatSelectedTaskId : formatEventTaskId;
                    const payload: TGanttLinkPayload = {
                        source,
                        target,
                        type: formatLinkType(type, false) as TGanttLinkType,
                        lag: event.data.lag,
                    };

                    const { deletePayload, linkId }: { deletePayload: TGanttLinkPayload; linkId: TLocalGanttLinkId } =
                        getDeletePayload(gantt, selectedTaskId, event.data.id);
                    try {
                        await editLinkDialog(gantt, projectId, grid, payload, deletePayload, linkId, dispatch);
                    } catch (error) {
                        console.log('error', error);
                        enqueueSnackbar('Работа выходит за рамки проекта', {
                            variant: 'error',
                        });
                    } finally {
                        setOverlayTitle(() => '');
                        setRefreshToggle(!refreshToggle);
                    }
                } else {
                    setOverlayTitle(() => 'Добавляем связь');
                    const payload: TGanttLinkPayload = {
                        source: formatSelectedTaskId,
                        target: formatEventTaskId,
                        type: formatLinkType(type, false) as TGanttLinkType,
                        lag: event.data.lag,
                    };
                    try {
                        await addLinkDialog(gantt, projectId, grid, payload, dispatch);
                    } catch (error) {
                        console.log('error', error);
                        if (!isAxiosError(error)) return;
                        if (isTooManyRequests(error)) return;

                        const errorData = error.response?.data;
                        if (isShiftErrorsData(errorData)) {
                            const shiftErrorsHandlingMap: {
                                [key: string]: () => void;
                            } = {
                                [ShiftErrorCode.OPERATIONAL_DATES_CHANGES_BLOCKED_BY_ZERO_FACT]: () => {
                                    openDeleteZeroValueFactDialog(errorData.zeroFactBlockers);
                                },
                                [ShiftErrorCode.OPERATIONAL_DATES_CHANGES_BLOCKED_BY_FACT]: () => {
                                    const sourceTask = gantt.getTask(payload.source) as ITask;
                                    const targetTask = gantt.getTask(payload.target) as ITask;

                                    setGanttLinkByFactRestrictionDialogData(() => ({
                                        sourceTask,
                                        targetTask,
                                        linkPayload: payload,
                                    }));
                                },
                            };
                            const handle = shiftErrorsHandlingMap[errorData.message] ?? null;
                            if (handle) {
                                handle();
                                return;
                            }
                        }

                        const DEFAULT_MESSAGE = 'Произошла ошибка при добавлении связи';
                        enqueueSnackbar(DEFAULT_MESSAGE, {
                            variant: 'error',
                        });
                    } finally {
                        setOverlayTitle(() => '');
                        setRefreshToggle(!refreshToggle);
                    }
                }
            }
            isValueChangedRef.current = false;
        }
    };

    const onCellValueChanged = async (event: CellValueChangedEvent) => {
        isValueChangedRef.current = true;
    };

    const onHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allIds = rowData.map((row) => row.id);
            setTargets(new Set(allIds));
        } else {
            setTargets(new Set());
        }
        gridRef.current.api.forEachNode((node: any) => {
            node.setSelected(isChecked);
        });
    };

    return (
        <DialogWindow
            open={Boolean(selectedTaskId)}
            onClose={onClose}
        >
            <Container>
                <Stack gap={2.5}>
                    <Title>Управление связями</Title>
                    <WorkNameContainer>
                        <WorkName>{taskName}</WorkName>
                    </WorkNameContainer>
                    {/* Придумать что-то с высотой. Нужна динамичность  */}
                    <FlexRowWrapper
                        className='table-wrapper'
                        height={Math.max((rowData.length + 1) * 40, 80)}
                        width={'100%'}
                        gap={0}
                    >
                        <div style={{ flex: '1 1 0', height: '100%' }}>
                            <StyledAgGrid
                                ref={gridRef}
                                editType='fullRow'
                                domLayout='autoHeight'
                                columnDefs={GanttLinkDialogColDefs({
                                    rowData,
                                    targets,
                                    setTargets,
                                    selectedTaskId,
                                    independentTasks,
                                    getTasksAsAutocompleteOptions,
                                    getTasksAsSearchAutocompleteOptions,
                                    onHeaderCheckboxChange,
                                })}
                                rowData={rowData}
                                gridOptions={{
                                    rowHeight: 40,
                                    singleClickEdit: true,
                                    rowSelection: 'multiple',
                                    stopEditingWhenCellsLoseFocus: true,
                                }}
                                headerHeight={40}
                                suppressMovableColumns
                                onCellValueChanged={onCellValueChanged}
                                onRowEditingStopped={onRowEditingStopped}
                                noRowsOverlayComponent={CustomNoRowsOverlay}
                            />
                        </div>
                    </FlexRowWrapper>
                </Stack>

                <Stack gap={2.5}>
                    <AddLinkButton onClick={handleAddEmptyRow}>
                        <PlusIcon />
                        Добавить связь
                    </AddLinkButton>
                    <Stack
                        direction={'row'}
                        gap={2}
                        justifyContent='space-around'
                    >
                        <RemoveButton
                            onClick={handleLinkDelete}
                            disabled={!targets.size}
                        >
                            Удалить
                        </RemoveButton>
                        <CloseButton onClick={resetState}>Закрыть</CloseButton>
                    </Stack>
                </Stack>
            </Container>
        </DialogWindow>
    );
};
