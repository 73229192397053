import { Stack } from '@mui/material'
import { CellValueChangedEvent, RowNode } from 'ag-grid-community'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FlexRowWrapper } from '../../../NewExecutorView/components/components.styles'
import { TLocalGanttLinkId } from '../../DHTGant.def'
import { IGanttSplitDialogProps } from './GanttSplitDialog.def'
import {
    AddLinkButton,
    CloseButton,
    Container,
    DialogWindow,
    PlusIcon,
    RemoveButton,
    Title,
    WorkName,
    WorkNameContainer,
    StyledAgGrid,
} from './GanttSplitDialog.styles'
import { useSnackbar } from 'notistack'
import { GanttSplitDialogColDefs } from './GanttSplitDialog.colDef'
import { ISplitWork, Work } from '../../../../api/ksg/ksg.def'
import { CustomNoRowsOverlay } from '../GanttLinkDialog/GanttLinkDialog.components'
import { useUpdateChartsCPGMutation } from '../../../../api/ksg/ksg.api'
import { batchUpdateTasksDates } from '../../DHTGantOnlyTable.utils'
import { onEditAgKsg } from '../../../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { useAppDispatch } from '../../../../store/store'
import { findSplitsBySegment, findSplitsDurations } from './GanttSplitDialog.utils'

export const GanttSplitDialog: React.FC<IGanttSplitDialogProps> = ({
    splitedTask,
    taskName,
    onClose,
    setOverlayTitle,
}) => {
    const { projectId } = useParams()
    const gridRef = useRef<any>(null)
    const isValueChangedRef = useRef<boolean>(false)
    const { enqueueSnackbar } = useSnackbar()
    const [rowData, setRowData] = useState<(ISplitWork & { number: number })[] | []>([])

    const dispatch = useAppDispatch()
    const [monthsUpdateReq, monthsUpdateRes] = useUpdateChartsCPGMutation()

    const handleAddEmptyRow = () => {
        const newRow = { number: rowData.length + 1, startDate: '', endDate: '' }
        setRowData([...rowData, newRow])
    }

    const resetState = () => {
        onClose()
    }

    const onRowEditingStopped = async (event: CellValueChangedEvent) => {
        if (isValueChangedRef.current) {
            isValueChangedRef.current = false
            if (!splitedTask || !projectId) return
            const startSplitDate = event.data.startDate
            const endSplitDate = event.data.endDate
            if (!startSplitDate || !endSplitDate) return
            const startDate = startSplitDate.split('.')
            const endDate = endSplitDate.split('.')
            const splitsPayload = findSplitsDurations(startDate as string[], endDate as string[])
            if (!splitsPayload) {
                setOverlayTitle(() => '')
                enqueueSnackbar('Введите корректные даты разрыва', {
                    variant: 'error',
                })
                return
            }
            setOverlayTitle(() => 'Добавляем разрывы')
            monthsUpdateReq({
                id: Number(projectId),
                body: {
                    toUpdate: [
                        {
                            charts: splitsPayload,
                            workID: splitedTask.id as number,
                        },
                    ],
                },
            })
                .unwrap()
                .then((res) => {
                    setOverlayTitle(() => '')
                    const updatedData = res.data as Work[]
                    //batchUpdateTasksDates(gantt)(works)
                    //grid.api.applyTransaction({ update: works })
                    dispatch(onEditAgKsg(updatedData))
                    enqueueSnackbar('Разрыв добавлен', {
                        variant: 'success',
                    })
                })
                .catch((e) => {
                    setOverlayTitle(() => '')
                    enqueueSnackbar('Ошибка при добавлении разрыва', {
                        variant: 'error',
                    })
                    console.error(e)
                })
        }
    }

    useEffect(() => {
        if (splitedTask) {
            const newSegments = splitedTask.segments.map((segment, index) => ({
                startDate: segment.startDate.slice(3),
                endDate: segment.endDate.slice(3),
            }))
            const newRowData = findSplitsBySegment(newSegments)
            setRowData(newRowData)
        }
    }, [splitedTask])

    const onCellValueChanged = async (event: CellValueChangedEvent) => {
        isValueChangedRef.current = true
    }

    return (
        <DialogWindow open={Boolean(splitedTask)} onClose={onClose}>
            <Container>
                <Stack gap={2.5}>
                    <Title>Выберите даты разрыва</Title>
                    <WorkNameContainer>
                        <WorkName>{taskName}</WorkName>
                    </WorkNameContainer>
                    <FlexRowWrapper
                        className="table-wrapper"
                        height={Math.max((rowData.length + 1) * 40 + 2, 80)}
                        width={'100%'}
                        gap={0}
                    >
                        <div style={{ flex: '1 1 0', height: '100%' }}>
                            <StyledAgGrid
                                ref={gridRef}
                                editType="fullRow"
                                columnDefs={GanttSplitDialogColDefs({
                                    rowData,
                                })}
                                rowData={rowData}
                                gridOptions={{
                                    rowHeight: 40,
                                    singleClickEdit: true,
                                    rowSelection: 'multiple',
                                    //stopEditingWhenCellsLoseFocus: true,
                                }}
                                headerHeight={40}
                                suppressMovableColumns
                                onCellValueChanged={onCellValueChanged}
                                onRowEditingStopped={onRowEditingStopped}
                                noRowsOverlayComponent={CustomNoRowsOverlay}
                            />
                        </div>
                    </FlexRowWrapper>
                </Stack>

                <Stack gap={2.5}>
                    <AddLinkButton onClick={handleAddEmptyRow}>
                        <PlusIcon />
                        Добавить разрыв
                    </AddLinkButton>
                    <Stack direction={'row'} gap={2} justifyContent="space-around">
                        {/* <RemoveButton onClick={handleLinkDelete} disabled={!targets.size}>
                            Удалить
                        </RemoveButton> */}
                        <CloseButton onClick={resetState}>Закрыть</CloseButton>
                    </Stack>
                </Stack>
            </Container>
        </DialogWindow>
    )
}
