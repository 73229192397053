import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { CgDanger } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import { Project } from '@/api/projects/projects.def';

import { monthsList } from '@/layouts/TableLayout/components/ChangeMonthTableTop';

import '../Projects.module.scss';
import s from '../Projects.module.scss';
import { ChartPayload } from '../Projects.service';

const CustomTooltip = ({ active, payload, label }: { active?: boolean; payload?: ChartPayload[]; label?: number }) => {
    if (!active || !payload || !label) return <></>;
    return (
        <div className={s.tp_wrapper}>
            <h4 className={s.tp_title}>{monthsList[payload[0].payload.month - 1]}</h4>
            <div className={s.tp_line}>
                <div
                    className={s.tp_dot}
                    style={{ backgroundColor: payload[0].color }}
                ></div>
                <p>{payload[0].name}:</p>
                <p>{payload[0].payload.fact}</p>
            </div>
            <div className={s.tp_line}>
                <div
                    className={s.tp_dot}
                    style={{ backgroundColor: payload[1].color }}
                ></div>
                <p>{payload[1].name}:</p>
                <p>{payload[1].payload.plan}</p>
            </div>
            <div className={s.tp_line}>
                <div
                    className={s.tp_dot}
                    style={{ backgroundColor: payload[2].color }}
                ></div>
                <p>{payload[2].name}:</p>
                <p>{payload[2].payload.basePlan}</p>
            </div>
        </div>
    );
};

export default function UserCard({ project }: { project: Project }) {
    return (
        <Link to={`/ksg/${project.id}`}>
            <section className={s.user_card}>
                <div className={s.user_top}>
                    <h2>{project.title}</h2>
                    <div>
                        <h1>{(project.done || 0) + '%'}</h1>
                        <span>ВЫПОЛНЕНО</span>
                    </div>
                </div>

                <div className={s.user_middle}>
                    <div className={s.user_line}>
                        <div>
                            <CgDanger
                                fontSize={20}
                                color='#5c6e8c'
                            />
                            <p>Статус:</p>
                        </div>
                        <h4>{project.status}</h4>
                    </div>
                    <div className={s.user_line}>
                        <div>
                            <CalendarMonthOutlinedIcon
                                sx={{
                                    width: '1.25rem',
                                    height: '1.25rem',
                                    color: '#5c6e8c',
                                }}
                            />
                            <p>Срок реализации:</p>
                        </div>
                        <h3>{project.startDate + ' - ' + project.endDate}</h3>
                    </div>
                </div>
                <div className={s.user_bottom}>
                    <ResponsiveContainer
                        width='100%'
                        height={230}
                    >
                        <AreaChart
                            data={project.charts.map((val, index) => {
                                return {
                                    month: val.month,
                                    fact: val.fact,
                                    plan: val.plan,
                                    basePlan: project.charts[index].plan,
                                };
                            })}
                            margin={{ top: 5, right: 5, left: 5, bottom: 0 }}
                        >
                            <defs>
                                <linearGradient
                                    id='colorFact'
                                    x1='0'
                                    y1='0'
                                    x2='0'
                                    y2='1'
                                >
                                    <stop
                                        offset='5%'
                                        stopColor='#FFB1B1'
                                        stopOpacity={0.15}
                                    />
                                    <stop
                                        offset='95%'
                                        stopColor='#FFB1B1'
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                                <linearGradient
                                    id='colorPlan'
                                    x1='0'
                                    y1='0'
                                    x2='0'
                                    y2='1'
                                >
                                    <stop
                                        offset='5%'
                                        stopColor='#6D9ADC'
                                        stopOpacity={0.2}
                                    />
                                    <stop
                                        offset='95%'
                                        stopColor='#6D9ADC'
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                                <linearGradient
                                    id='colorBasePlan'
                                    x1='0'
                                    y1='0'
                                    x2='0'
                                    y2='1'
                                >
                                    <stop
                                        offset='5%'
                                        stopColor='#d4dce7'
                                        stopOpacity={0.2}
                                    />
                                    <stop
                                        offset='95%'
                                        stopColor='#d4dce7'
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey='month'
                                interval={0}
                                strokeWidth={0}
                                fontSize={14}
                                width={100}
                            />
                            <CartesianGrid
                                strokeDasharray='2 2'
                                horizontal={false}
                            />
                            <Tooltip
                                wrapperStyle={{ zIndex: 1000, outline: 0 }}
                                content={<CustomTooltip />}
                            />
                            <Area
                                type='monotone'
                                dataKey='fact'
                                name='Факт'
                                stroke='#FFB1B1'
                                fillOpacity={1}
                                fill='url(#colorFact)'
                                strokeWidth={2}
                            />
                            <Area
                                type='monotone'
                                dataKey='plan'
                                name='План'
                                stroke='#6D9ADC'
                                fillOpacity={1}
                                fill='url(#colorPlan)'
                                strokeWidth={2}
                            />
                            <Area
                                type='monotone'
                                dataKey='basePlan'
                                name='Базовый план'
                                stroke='#d4dce7'
                                fillOpacity={1}
                                fill='url(#colorBasePlan)'
                                strokeWidth={2}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </section>
        </Link>
    );
}
