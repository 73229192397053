import {
    useGetFiltersListEquipQuery,
    useGetFiltersListMsgEquipQuery,
    useGetFiltersListMsgPeopleQuery,
    useGetFiltersListPeopleQuery,
} from '../../api/filters/filters.api'
import { useParams } from 'react-router-dom'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { ChangeEvent, useMemo, useState } from 'react'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { newDate } from '../../store/slices/cpgViewSlice'
import s from '../CheckboxPopover/CheckboxPopover.module.scss'
import { FaFilter } from 'react-icons/fa'
import { Button } from '@mui/material'
import { DebounceInput } from 'react-debounce-input'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { projectsSelector } from '../../store/slices/projectsSlice'
import { pagesNames } from '../..'
import { monthMsgSelector } from '../../store/slices/monthMsgSlice'
import {
    filtersWorkersMimSelector,
    resetFilterTitlesArr,
    setFilterTitlesArr,
} from '../../store/slices/filtersWorkersMimSlice'

export default function CheckboxPopoverWorkersMimKsg() {
    const [inputValue, setInputValue] = useState('')

    const { titlesArr } = useTypedSelector(filtersWorkersMimSelector)
    const { month, year } = useTypedSelector(monthMsgSelector)
    const { pageName } = useTypedSelector(projectsSelector)

    const { projectId } = useParams()
    const dispatch = useAppDispatch()

    const filtersListMsgEquip = useGetFiltersListMsgEquipQuery(
        {
            projectId: Number(projectId),
            year: year,
            month: month + 1,
        },
        { skip: pageName !== pagesNames.msg_equip }
    )
    const filtersListMsgPeople = useGetFiltersListMsgPeopleQuery(
        {
            projectId: Number(projectId),
            year: year,
            month: month + 1,
        },
        { skip: pageName !== pagesNames.msg_people }
    )
    const filtersListEquip = useGetFiltersListEquipQuery(
        { projectId: Number(projectId) },
        { skip: pageName !== pagesNames.ksg_equip }
    )
    const filtersListPeople = useGetFiltersListPeopleQuery(
        { projectId: Number(projectId) },
        { skip: pageName !== pagesNames.ksg_people }
    )

    const checkboxesList = useMemo(() => {
        switch (pageName) {
            case pagesNames.msg_equip:
                return filtersListMsgEquip
            case pagesNames.msg_people:
                return filtersListMsgPeople
            case pagesNames.ksg_equip:
                return filtersListEquip
            case pagesNames.ksg_people:
                return filtersListPeople
        }
    }, [filtersListEquip, filtersListMsgEquip, filtersListMsgPeople, filtersListPeople, pageName])

    const handleChange = (event: ChangeEvent<HTMLInputElement>, arrItem: string) => {
        dispatch(setFilterTitlesArr({ title: arrItem, checked: event.target.checked }))
    }

    const filteredCheckboxesList = useMemo(() => {
        return checkboxesList?.data?.data.filter((item) => item.toLowerCase().includes(inputValue.toLowerCase()))
    }, [checkboxesList?.data?.data, inputValue])

    return (
        <div className={s.main}>
            <div className={s.top_section}>
                <div className={s.top}>
                    <div className={s.filterTitle}>
                        <FaFilter color="#0044b4" />
                        <h4>Наименование</h4>
                    </div>
                    <Button
                        sx={{
                            color: '#7890B2',
                            padding: '0.1rem 0.5rem',
                            fontWeight: '400',
                        }}
                        onClick={() => dispatch(resetFilterTitlesArr())}
                    >
                        Сбросить фильтр
                    </Button>
                </div>

                <div className={s.input}>
                    <DebounceInput
                        value={inputValue}
                        placeholder="Поиск"
                        debounceTimeout={300}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <span>
                        <SearchOutlinedIcon
                            sx={{
                                color: '#7890B2',
                            }}
                        />
                    </span>
                </div>
            </div>
            <div className={s.checkboxes_peq}>
                <FormGroup>
                    {filteredCheckboxesList?.map((checkbox, index) => (
                        <FormControlLabel
                            control={<Checkbox />}
                            label={checkbox}
                            key={`${checkbox}${index}`}
                            sx={{
                                '.MuiTypography-root': {
                                    color: '#0044B4',
                                    fontWeight: 500,
                                    inlineSize: 270,
                                    overflowWrap: ' break-word',
                                },
                                '.MuiSvgIcon-root': {
                                    fill: '#0044B4',
                                },
                            }}
                            checked={titlesArr.includes(checkbox)}
                            onChange={(e) => handleChange(e as ChangeEvent<HTMLInputElement>, checkbox)}
                        />
                    ))}
                </FormGroup>
            </div>
        </div>
    )
}
