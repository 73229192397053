import { ISplitWork } from '../../../../api/ksg/ksg.def'

export const findSplitsBySegment = (segments: ISplitWork[]) => {
    const splits = [] as (ISplitWork & { number: number })[]
    segments.forEach(
        (segment, i) =>
            i < segments.length - 1 &&
            splits.push({
                startDate: formatSplitStartDate(segment.endDate),
                endDate: formatSplitEndDate(segments[i + 1].startDate),
                number: i + 1,
            })
    )
    return splits
}

const formatSplitStartDate = (date: string) => {
    const monthStart = Number(date.split('.')[0])
    const yearStart = Number(date.split('.')[1])
    return monthStart + 1 > 12
        ? `01.${yearStart + 1}`
        : `${monthStart + 1 < 10 ? '0' + (monthStart + 1) : monthStart + 1}.${yearStart}`
}

const formatSplitEndDate = (date: string) => {
    const monthEnd = Number(date.split('.')[0])
    const yearEnd = Number(date.split('.')[1])
    return monthEnd - 1 < 1
        ? `12.${yearEnd - 1}`
        : `${monthEnd - 1 < 10 ? '0' + (monthEnd - 1) : monthEnd - 1}.${yearEnd}`
}

export const findSplitsDurations = (startDate: string[], endDate: string[]) => {
    const splits = []
    const monthStart = Number(startDate[0])
    const yearStart = Number(startDate[1])
    const monthEnd = Number(endDate[0])
    const yearEnd = Number(endDate[1])
    if (yearStart === yearEnd) {
        if (monthStart > monthEnd) return
        for (let i = monthStart; i <= monthEnd; i++) {
            splits.push({
                month: i,
                plan: null,
                fact: null,
                year: yearStart,
            })
        }
    } else if (yearStart < yearEnd) {
        for (let i = monthStart; i <= 12; i++) {
            splits.push({
                month: i,
                plan: null,
                fact: null,
                year: yearStart,
            })
        }
        for (let i = 1; i <= monthEnd; i++) {
            splits.push({
                month: i,
                plan: null,
                fact: null,
                year: yearEnd,
            })
        }
        if (yearStart + 1 < yearEnd) {
            for (let j = yearStart + 1; j < yearEnd; j++) {
                for (let i = 1; i <= 12; i++) {
                    splits.push({
                        month: i,
                        plan: null,
                        fact: null,
                        year: j,
                    })
                }
            }
        }
    } else {
        return
    }
    return splits
}
