import { ColDef, ColGroupDef } from 'ag-grid-community'
import { BaseCellDate, CustomCellEditor } from '../GanttLinkDialog/GanttLinkDialog.components'
import { IGanttSplitDialogColDefProps } from './GanttSplitDialog.def'

export const GanttSplitDialogColDefs = ({ rowData }: IGanttSplitDialogColDefProps): ColDef[] | ColGroupDef[] =>
    rowData
        ? [
              {
                  headerName: 'Дата старта',
                  field: 'startDate',
                  maxWidth: 300,
                  editable: true,
                  cellRenderer: BaseCellDate,
                  cellEditor: CustomCellEditor,
                  cellEditorParams: {
                      dataType: 'date',
                  },
                  cellClass: 'ag-cell-left ag-cell-editable',
              },
              {
                  headerName: 'Дата конца',
                  field: 'endDate',
                  maxWidth: 300,
                  editable: true,
                  cellRenderer: BaseCellDate,
                  cellEditor: CustomCellEditor,
                  cellEditorParams: {
                      dataType: 'date',
                  },
                  cellClass: 'ag-cell-left ag-cell-editable',
              },
          ]
        : []
