import s from './CheckboxPopoverSufficiency.module.scss'
import { FaFilter } from 'react-icons/fa'
import { filtersNamesSmEnum, filterVarSom } from '../../pages/SufficiencyOfMaterials/SufficiencyOfMaterials.service'

export default function CheckboxPopoverSufficiency({ name }: { name: filtersNamesSmEnum }) {
    return (
        <div className={s.main}>
            <div className={s.top}>
                <div className={s.filterTitle}>
                    <FaFilter color="#0044b4" />
                    <h4>{filterVarSom[name].title}</h4>
                </div>
            </div>

            <div className={name === 'dateDel' ? s.bottom_section : ''}>{filterVarSom[name].elem}</div>
            {filterVarSom[name].buttons}
        </div>
    )
}
